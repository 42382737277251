import React, { Component } from 'react'

import Image from '../../assets/images/NewHeader.jpg'
import transferImage from '../../assets/images/transfer-bg2.jpg'

import PageHeader from '../../components/PageHeader/PageHeader'
import Cities from '../../components/Cities/Cities'
import CitiesComingSoon from '../../components/Cities/CitiesComingSoon'

import './To.scss'

export default class Tours extends Component {
  render() {
    return (
      <div id='to'>
        <PageHeader title='Transfers' subtitle='Where would you like to go?' image={transferImage} />

        <Cities />

        <CitiesComingSoon />
      </div>
    )
  }
}

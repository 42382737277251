import React, { Component } from 'react'

import { NavLink } from 'react-router-dom'

import { Col, Container, Row } from 'react-bootstrap'

import { Link } from 'react-router-dom'
import './Footer.scss'

import { SocialIcon } from 'react-social-icons';


export default class Footer extends Component {
  constructor() {
    super()

    this.state = {
      tourMenu: [
        { name: 'Barcelona', link: '/barcelona' },
        { name: 'Florence', link: '/florence' },
        { name: 'Milan', link: '/milan' },
        { name: 'Rome', link: '/rome' },
      ],
      companyMenu: [
        { name: 'About Us', link: '/about' },{ name: 'Contact Us', link: '/contact' }
      ]
    }
  }

  render() {
    return (
      <Container fluid className='footer'>
        <Container>
          <Row className="justify-content-between">
            <Col xs={7} md={4}>
              <h5>GetCabbo</h5>
              <ul>
                {this.state.companyMenu.map((item, key) => <li key={key}><NavLink exact className="nav-link" activeClassName="active" to={item.link}>{item.name}</NavLink></li>)}
              </ul>
              <br />
              <h6>Toll-Free Phone</h6>
              <p>
                +44 800 090 3170
              </p>
            </Col>
            <Col xs={5} md={4}>
              <h6>Cities</h6>
              <ul>
                {this.state.tourMenu.map((item, key) => <li key={key}><NavLink exact className="nav-link" activeClassName="active" to={'/to' + item.link}>{item.name}</NavLink></li>)}
              </ul>
            </Col>
          </Row>
          <Row className='mt-5'>
            <Col xs={6} md={4}>
              <p className='mb-0 small'>
                <Link to='/terms' className='mr-5'>Terms &amp; Conditions</Link>
              </p>
              <p className='mb-0 small'>
                <Link to='/privacy'>Privacy Policy</Link>
              </p>
            </Col>
            <Col xs={6} md={4}>
            <center>
            <SocialIcon url="https://www.linkedin.com/company/getcabbo" style={{ height: 25, width: 25 }}/>
            <span>&nbsp;&nbsp;</span>
            <SocialIcon url="https://www.facebook.com/getcabbo" style={{ height: 25, width: 25 }}/>
            <span>&nbsp;&nbsp;</span>
            <SocialIcon url="https://www.instagram.com/getcabbo/" style={{ height: 25, width: 25 }}/>
            <span>&nbsp;&nbsp;</span>
            <SocialIcon url="https://wa.me/448000903170" network="whatsapp" style={{ height: 25, width: 25 }}/>
            </center>
            </Col>
            <Col xs={12} md={4}>
              <p className='mb-0 small'><center>&copy; GetCabbo { new Date().getFullYear() }. All Rights Reserved.</center></p>
            </Col>
          </Row>
        </Container>
      </Container>
    )
  }
}

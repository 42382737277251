import React from 'react'
import './loader.scss'

export default function Loader(props) {
    return (
        <div className={`circle-loader ${props.completed ? 'load-complete' : ''}`}>
          <div className="checkmark draw"></div>
        </div>        
    )
}

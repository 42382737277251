import React, { Component } from 'react';
import { Card, Form, Container } from 'react-bootstrap'
import Loader from '../Loader/Loader'


class TourForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            name: '',
            email: '',
            phone: '',
            selectedTour: props.tours[0].title,
            isCompleted: false,
            isSubmitting: false,
            isSubmitted: false,
            progressVisible: false,
            errors: {
                name: '',
                email: '',
                phone: ''
            }
        }
    }

    sendMail() {
        this.setState({isSubmitting: true});
        console.log("submitting")
        window.Email.send({
          Host : "getcabbo.com",
          Username : "mailhook@getcabbo.com",
          Password : "Y68GkE@]BfUW",
          To : 'mailhook@getcabbo.com',
          From : "mailhook@getcabbo.com",
          Subject : "Booking From " + this.state.name,
          Body : "<div>Hello,<br/>This is an email regarding a booking by " + this.state.name + ".<br/>The details of the tour are as follows :-<br/>Tour name: "+ this.state.selectedTour + "<br/> Phone Number: " + this.state.phone + "<br/> Email Address: " + this.state.email + "</div>"
       }).then((message) => {
            if(message){
             console.log(message)
             this.setState({
                name: '',
                email: '',
                phone: '',
                isCompleted: true,
                isSubmitting: false
             })
            }
          });
      }

    checkErrors() {
        const validEmailRegex = RegExp(/^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i);
        const validPhoneRegex = RegExp(/^\s*(?:\+?(\d{1,3}))?[-. (]*(\d{3})[-. )]*(\d{3})[-. ]*(\d{4})(?: *x(\d+))?\s*$/i);
        var errorExists = false;
        if (this.state.name === '') {
            this.setState({errors: {name: 'Enter a name' }});
            errorExists = true;
        }
        if (!validEmailRegex.test(this.state.email)) {
            this.setState(prevState => ({errors: {...prevState.errors, email: 'Email is not valid'}}));
            errorExists = true;
        }
        if (!validPhoneRegex.test(this.state.phone)) {
            this.setState(prevState => ({errors: {...prevState.errors, phone: 'Enter a valid phone number'}}));
            errorExists = true;
        }
        return errorExists;
    }

    handleSubmit(e) {
        e.preventDefault();
        this.setState({isSubmitted: false, isCompleted: false, errors: {name: '', email: '', phone: ''}});
        if (!this.checkErrors()) {
            this.sendMail();
        }
    }

    handleChange(param, e) {
        this.setState({[param]: e.target.value});
    }

    componentDidUpdate() {
        if (this.state.isSubmitting && !this.state.progressVisible) {
            this.setState({progressVisible: true});
        }

        if (this.state.isCompleted && !this.state.isSubmitted) {
            setTimeout(() => {
                this.setState({progressVisible: false, isSubmitted: true})
            }, 1500)
        }
    }

    render() {
        const {tours} = this.props;
        const {name, email, phone, selectedTour, isSubmitting, isCompleted, progressVisible} = this.state;
        
        return (
            <>
            <Card className="tour-form rounded bg-white d-flex flex-column p-4">
              <Card.Body className="p-0">
                    <Form className="p-0" as={Container}>
                        <Card.Title>Book Now</Card.Title>
                        <p>Ask For more information</p>
                        <Form.Row>
                            <Form.Control type="text" defaultValue={name} value={name} name="name" id="name" placeholder="First & Last name" onChange={this.handleChange.bind(this, 'name')}/>
                            {this.state.errors.name !== '' ? <span className="error text-danger">{this.state.errors.name}</span> : <></>}
                        </Form.Row>
                        <Form.Row>
                            <Form.Control type="text" defaultValue={email} value={email} name="email" id="email" placeholder="Contact Email" onChange={this.handleChange.bind(this, 'email')}/>
                            {this.state.errors.email !== '' ? <span className="error text-danger">{this.state.errors.email}</span> : <></>}
                        </Form.Row>
                        <Form.Row>
                            <Form.Control type="tel" defaultValue={phone} value={phone} name="phone" id="phone" placeholder="Phone number" onChange={this.handleChange.bind(this, 'phone')}/>
                            {this.state.errors.phone !== '' ? <span className="error text-danger">{this.state.errors.phone}</span> : <></>}
                        </Form.Row>
                        <Form.Row>
                            <Form.Group className="d-flex flex-column">
                                <label for="tourname" className="mb-0">Which tour are you interested in?</label>
                                <div className="select-wrapper">
                                    <Form.Control as="select" name="tour" value={selectedTour} id="tourname" onChange={this.handleChange.bind(this, 'selectedTour')}>
                                        {tours.map( (tour, i) => {
                                            return ( 
                                                <option key={i} value={tour.title}>{tour.title}</option>
                                            )
                                        })}
                                    </Form.Control>
                                </div>
                            </Form.Group>
                        </Form.Row>
                        <input type="submit" value="submit" className="btn btn-primary" onClick={(e) => this.handleSubmit(e)} />                     
                    </Form>
                </Card.Body>
            </Card>
            {progressVisible === true ?
                <div className="popup-overlay background-blur d-flex flex-column align-items-center justify-content-center">
                    <Loader completed={isCompleted} />
                    <h1 className="text-white">{isSubmitting ? 'Submitting...' : 'Submitted'}</h1>
                </div>
                : 
                <>
                
                </>
            }
            </>
        );
    }
}

export default TourForm;
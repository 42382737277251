import * as firebase from "firebase/app";
import "firebase/auth";
const API_ENDPOINT = "https://us-central1-getcabbo-1549539564765.cloudfunctions.net/webApi/v1"
// const API_ENDPOINT = "http://localhost:5000/getcabbo-1549539564765/us-central1/webApi/v1"


async function makeApiRequest(url, payload = null, opts = {}) {
  let myUrl = url.indexOf('/') === 0 ? `${API_ENDPOINT}${url}` : url;

  let userToken = await firebase.auth().currentUser.getIdToken(true);

  // console.log(userToken);

  const options = {};

  if (payload) {
    options.method = opts.method || 'POST';
    const len = Object.keys(payload).length;

    if (options.method === 'GET') {
      myUrl += len ? '?' : '';

      Object.keys(payload).forEach(key => {
        myUrl += `${key}=${payload[key]}${len - 1 > 0 ? '&' : ''}`;
      });

      // console.log(myUrl);
    } else {
      options.body = JSON.stringify(payload);
    }
  }

  options.headers = {
    ...opts.headers,
    ...(opts.noauth ? {} : { Authorization: `Bearer ${userToken}` }),
    'Content-type': 'application/json',
  };

  if (opts.mode) {
    options.mode = opts.mode;
  }

  return fetch(myUrl, options)
    .then(async res => {
      if (!res.ok) {
        const err = await res.json();
        console.log(err)
        return null;
        // throw err.message || res.statusText;
      }
      return opts.raw ? res.text() : res.json();
    })
    .catch(err => {
      // throw new Error(err);
      console.log(err)
    });
}

async function testAuth() {
  let req = await makeApiRequest('/auth', {}, { method: 'GET', raw: true });;
  console.log(req);
}

export { testAuth };
export default makeApiRequest;
import React, { Component } from 'react'
import { Col, Container, Row, Card } from 'react-bootstrap'
import Star from './Star'
import trustPilotLogo from '../../assets/images/trustpilot-logo2.png'
import Slider from "react-slick";


export default class Reviews extends Component {
    constructor() {
        super();
        this.state = {
            reviews: [
                {
                  avatar: 'https://via.placeholder.com/150/FFFFFF/FFFFFF/FFFFFF',
                  name:'P.B.H.',
                  stars:'5',
                  text: 'Reliable, punctual, careful and helpful driver. Car roomy, clean and tidy. Recommended.'
                },
                {
                  avatar: 'https://via.placeholder.com/150/FFFFFF/FFFFFF/FFFFFF',
                  name:'Luigi Pedace',
                  stars:'5',
                  text: "I often use Cabbo and I've never been disappointed. Quality service and very professional drivers."
                },
                {
                  avatar: 'https://via.placeholder.com/150/FFFFFF/FFFFFF/FFFFFF',
                  name:'Katie Raymond',
                  stars:'5',
                  text: 'Ideal airport transportation! Could not have been easier. He took our bags and then a smooth ride to our destination'
                },
              ],
        }
    }
    
    render() {
        const {reviews} = this.state;
        const sliderSettings = {
            className: "slider variable-width", 
            mobileFirst: true,
            centerMode: true,  
            infinite: true,
            variableWidth: true,
            speed: 500,
            slidesToShow: 1,
            slidesToScroll: 1,
            autoplay: true,
            autoplaySpeed: 2000,
            cssEase: "linear",
            arrows : false,
            responsive: [
                {
                  breakpoint: 1024,
                  settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    infinite: true,
                    variableWidth: false,
                    centerMode: false
                  }
                },
                {
                  breakpoint: 600,
                  settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    variableWidth: false,
                    centerMode: false
                  }
                },
                {
                  breakpoint: 480,
                  settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    centerMode: false,
                    variableWidth: false
                  }
                }
              ]
          };

        return (
            <Container fluid className="bg-light-gray py-5 reviews-section">
                <Container>
                    <Row>
                        <Col xs={12}>
                            <img src={trustPilotLogo} alt="Trustpilot Logo" className="mb-4" style={{maxWidth: '14rem'}}/>
                        </Col>
                    </Row>
                </Container>
                <Row>
                    <Col xs={12}>
                        <Slider {...sliderSettings}>
                            {reviews.map((review, i) => {
                                return (
                                    <div key={i} className='mr-3'>
                                        <Card className="text-center review-card p-3 shadow mr-3" style={{width: '100%', maxWidth: '24rem', minHeight: '180px'}}>
                                            <Card.Header className="bg-white d-flex align-items-center border-0 px-0 py-1">
                                                {/* <img style={{maxWidth: '3rem'}} src={review.avatar} className="float-left rounded-circle mr-3" alt="avatar"/> */}
                                                <Star numStars={review.stars} />
                                            </Card.Header>
                                            <Card.Body className="p-1 d-flex align-items-center">
                                                {review.text}
                                            </Card.Body>
                                            <Card.Footer className="text-muted text-right bg-white border-0 py-1">
                                                -{review.name}
                                            </Card.Footer>
                                        </Card>
                                    </div> 
                                )
                            })}
                        </Slider>
                    </Col> 
                </Row>
            </Container>
        )
    }
}
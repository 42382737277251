import React, { Component } from 'react'

import Destination from '../../components/Destination/Destination'

import FlorenceImage from '../../assets/images/Florence.png'
import NipozzanoCastle from '../../assets/images/Florence/1/Main.png'
import ValleyOfChianti from '../../assets/images/Florence/2/Main.png'

export default class Milan extends Component {
  constructor() {
    super()

    this.state = {
      title: 'Florence',
      country:'it',
      description: 'Cradle of the Renaissance, still wonderfully preserved, filled with a wealth of unique architectural and artistic treasures. Romantic, enchanting and utterly irresistible Florence (Firenze) is a place to feast on world-class art and gourmet Tuscan cuisine.',
      story: <div>
        Florence's museums, palaces, and churches house some of the greatest artistic treasures in the world. The most popular and important sites in Florence include the Cathedral, the Baptistery, the Uffizi, the Bargello, and the Accademia. The churches of Santa Maria Novella and Santa Croce are veritable art galleries, and the library of San Lorenzo is a magnificent exhibition of Michelangelo's architectural genius.
<br/>
 Wander some of the oldest streets in the city until you reach the Arno River, cross the Ponte Vecchio, and experience the "newest" area of Florence, the Oltrarno. Be sure to set aside time to see the vast and varied art collection housed in the Pitti Palace. When you grow weary of museums and monuments, head outdoors. Spend a day at the Boboli Gardens or climb the hill to the church of San Miniato al Monte to experience an enchanting view of Florence, Italy.
  <br/>
 Florence and its magnificent treasures await your visit!

      </div>,
      tours: [
        {
          title: 'Nipozzano Castle',
          code:21,
          description: {
            brief: 'Take a day trip to Nipozzano Castle: the ancient stronghold in defence of Florence, known for its traditional winemaking.             ',
            full: 'The first evidence of the quality of the Nipozzano wine dates back to the Renaissance, when even the great artists from Donatello and Michelozzo Michelozzi used to buy this wine. By 1855, wine production had grown to be a part of the Nipozzano to the point that an enlightened Frescobaldi ancestor decided to import the cultivation of Cabernet Sauvignon Merlot, Cabernet Franc and Petit Verdot grapevines. Now, the Castles are renowned for the elegant label of the Mormoreto estate.'
          },
          duration: '8 Hours',
          includes: 'English speaking driver and its vehicle available for the duration of the tour, tools, passenger liability insurance that is required by law and fuel.          ',
          excludes: 'Entrance fees to sites, museums and meals.',
          price: '650€ 1-3 persons | 750€ 4-6 persons | 830€ 7-8 persons',
          image: NipozzanoCastle
        },
        {
          title: 'Valley of Chianti Eco-Tour',
          code:22,
          description: {
            brief: 'A story that began in 1385. A historic link with the Chianti Classico territory. A place where tradition and innovation coexist harmoniously. ',
            full: <div>
              A story that began in 1385. A historic link with the Chianti Classico territory. A place where tradition and innovation coexist harmoniously.
              <br/>
              <b>A cellar able to tell the past, present and future of the Antinori family.</b><br/>
              The winery, inaugurated on October 25th 2012, is the confirmation of the historic link between the Antinori family and its homeland. Built entirely with local materials and with great respect for the environment and the Tuscan landscape, it represents a milestone in the history of the family.
              For the first time in 26 generations, the Antinori family inaugurates a winery designed to accommodate wine lovers, designed to come into direct contact with its production philosophy, made up of passion, patience and the continuous search for quality.<br/>
              <b>A window on the Chianti Classico</b><br/>
              The winery overlooks the countryside through a terrace surrounded by the vineyard cultivated with the typical Chianti Classico variety, the Sangiovese, together with the others that are historically placed side by side: the Canaiolo, the Ciliegiolo, the Colorino, the black Malvasia, the Mammolo. A small part is also dedicated to international vines, the Cabernet Sauvignon and Franc.<br/>
              <b>Innovative architecture</b><br/>
              Conceived to have a low environmental impact and high energy savings, the winery is a fascinating and unusual place.
              <br/>
              Virtually invisible from the outside, if not for two long horizontal “cracks” that cross the hill and correspond to the front of the building, its distinctive helical sign is the dramatic spiral staircase that connects the 3 floors of the structure.
              <br/>
              The Antinori winery in the Chianti Classico area is part of Toscana Wine Architecture, a circuit that combines designer and wine cellars, signed by the great masters of contemporary architecture.<br/>
              <b>A sacred place of silence</b><br/>
              The cellar has been designed to allow winemaking "by gravity" and to guarantee the ideal temperature for wine production and storage in a natural way.
              "It is a sacred place of silence, temple of ancient rites of grapes, but at the same time it is a place of production that must meet certain quality requirements."
              Cit. Marco Casamonti, architect of Antinori in the Chianti Classico.
            </div>
          },
          duration: '8 Hours',
          includes: 'English speaking driver and its vehicle available for the duration of the tour,tools, passenger liability insurance that is required by law and fuel.',
          excludes: 'Entrance fees to sites, museums and meals.',
          price: '650€ 1-3 persons | 750€ 4-6 persons | 830€ 7-8 persons',
          image: ValleyOfChianti
        },
      ],
      knowledge: [
        {
          title: 'Florence is Best Explored on Foot',
          content: 'The historic center of Florence is pretty compact, so everything can be reached in 20 minutes or less. '
        },
        {
          title: 'Florence Isn’t Expensive',
          content: <div>
            Many visitors are afraid of visiting Florence because they expect high prices. While the accommodation prices aren’t the lowest, food can surely be affordable if you avoid typical tourist traps such as eating next to Piazza del Duomo or Ponte Vecchio.
            Most things to do in Florence are actually free too, or costs between 6 to 10 Euros.<br/>
            <b>Our Tip</b>: Go to Gelateria Dei Neri
          </div>
        },
        {
          title: 'Italians Eat Dinner Late',
          content: <div>
            Italians eat dinner late, usually around 8:00-9:00. Most restaurants around even open until 7 - 7:30 PM. Before dinner, they go for an aperitivo to a bar or enoteca (wine bar) which is sort of a happy hour, but not quite. Many bars offer a complimentary snack buffet with cured meats and cheeses, to go with your cocktail.<br/>
            <b>Our Tip</b>: Try Aperol Spritz.
          </div>
        },
        {
          title: 'Most restaurants charge a cover for table seating, listed as coperto on the menu and receipt.',
          content: <div>
            This is typically €1-2 per person, including children, but in touristy areas, the cover charge might be up to €4. This fee is just for occupying space and doesn’t include any free bread or water.<br/>
            It must be listed on the menu, and if it’s not you may have it waived. Don’t get too mad at coperto, since it’s not customary to tip at every meal in Italy you can think of it as a tip.
            Visit One of the Rooftop Bars in Florence<br/>
            While Florence doesn’t seem like a place that would be full of rooftop bars, it has a few great ones that offer a different experience, far from the crowds.
            Here are some of our favorite picks for an aperitivo<br/>
            <ul>
            <li>Grand Hotel Cavour, Via del Proconsolo 3.</li>
            <li>Grand Hotel Minerva, Piazza Santa Maria Novella 16.</li>
            <li>SE·STO on Arno Rooftop at Westin Excelsior, Borgo Ognissanti</li>
            </ul>
          </div>
        },
        {
          title: 'Don’t plan on taking an Uber',
          content: 'Uber currently doesn’t operate in Italy. The Italian government banned it in 2017 after some protests against the company’s policies. Use Cabbo for your local transportation, we are compliant with the local laws.'
        },
        {
          title: 'As with all major cities, pickpocketing is a real threat.  ',
          content: 'Mind your belongings at all times. Don’t put your bag on the ground unattended and don’t carry large amounts of cash.'
        },
        {
          title: 'You pay for table service in Italy. ',
          content: 'If you’re visiting Italy for the first time, you may be surprised to learn that you will pay a coperto for sitting down at the table to eat.'
        },
        {
          title: 'Don’t be afraid to speak up if your bill is wrong. ',
          content: 'Always get an itemized receipt when paying for your meal and read it carefully. Know what you are being charged for. '
        },
        {
          title: 'Tipping is not mandatory or expected in Europe.',
          content: 'Italian waitstaff are paid a respectable wage.  If Italians leave a tip, it’s a few euros or rounding up of the bill.           '
        }
      ],
      reviews: [
        {
          avatar: 'https://via.placeholder.com/150/FFFFFF/FFFFFF/FFFFFF',
          name:'P.B.H.',
          stars:'5',
          text: 'Reliable, punctual, careful and helpful driver. Car roomy, clean and tidy. Recommended.'
        },
        {
          avatar: 'https://via.placeholder.com/150/FFFFFF/FFFFFF/FFFFFF',
          name:'Luigi Pedace',
          stars:'5',
          text: "I often use Cabbo and I've never been disappointed. Quality service and very professional drivers."
        },
        {
          avatar: 'https://via.placeholder.com/150/FFFFFF/FFFFFF/FFFFFF',
          name:'Katie Raymond',
          stars:'5',
          text: 'Ideal airport transportation! Could not have been easier. He took our bags and then a smooth ride to our destination'
        },
      ],
      faq: [
        {
          question: 'What is included in the price',
          answer: <ul>
            <li>Pickup and dropoff</li>
            <li>Free wifi onboard</li>
            <li>All taxes, fees and handling charges</li>
            <li>Water and Snacks</li>
            <li>Well-maintained car with an English-speaking driver</li>
          </ul>,
        },
        {
          question: 'What is not included in the price',
          answer: <ul>
            <li>Food and drinks, not specified in tour itinerary</li>
            <li>Entrance fees to attractions</li>
            <li>Official tour guide (you can include one after the booking)</li>
            <li>Gratuities</li>
          </ul>,
        },
        {
          question: 'Pick up from Firenze-Peretola (Florence)',
          answer: <div>
            Otherwise known as Florence Airport, Peretola (FLR):<br/>
            1) Pick up your luggage and leave the baggage claim area;<br/>
            2) The Driver will have checked your flight number so he will be waiting for you at the arrivals area;<br/>
            3) By the Arrivals, you will find a lot of drivers. Look for the one holding the signboard with the name you indicated in the booking form;<br/>
            4) Let the driver help you with your luggage;<br/>
            5) Enjoy your trip! Remember that Cabbo Drivers know all the secrets of the city.
          </div>,
        },
        {
          question: 'Pick up from Pisa Airport',
          answer: <div>
            Otherwise known as Galileo Galilei Airport, (PSA):<br/>
            1) Pick up your luggage and leave the baggage claim area;<br/>
            2) The Driver will have checked your flight number so he will be waiting for you at the arrivals area;<br/>
            3) By the Arrivals, you will find a lot of drivers. Look for the one holding the signboard with the name you indicated in the booking form;<br/>
            4) Let the driver help you with your luggage;<br/>
            5) Enjoy your trip! Remember that Cabbo Drivers know all the secrets of the city.
          </div>,
        },
        {
          question: 'Pick up from Stazione Firenze Santa Maria Novella',
          answer: <div>

          1) Walk out from the Platforms Area<br/>
          2) Your Cabbo Ride will be waiting for you in front of "Farmacia Santa Maria Novella" (BIG GREEN CROSS), holding a signboard with the name you indicated on the Booking Form.<br/>
          3) Let the driver help you with your bags;<br/>
          4) Enjoy your trip! Remember that Cabbo Drivers know all the secrets of the city.

          </div>,
        },
        {
          question: 'Pick up from Livorno/La Spezia Port',
          answer: <div>
          1) Get off the ship;<br/>
          2) Search among the drivers the one holding a signboard with the name you specified in the Booking Form. All of our drivers are equipped with a license to come get you right as you disembark;<br/>
          3) Let the driver help you with your bags;<br/>
          4) Enjoy your trip! Remember that Cabbo Drivers know all the secrets of the city.
          </div>,
        },
        {
          question: 'Pick up from another address',
          answer: <div>
            1) The driver will meet you outside of the specified location, you will receive an SMS notification if you don’t meet by the indicated time.<br/>
            2) Let the driver help you with your bags;<br/>
            3) Enjoy your ride. Don’t forget to ask your Cabbo Driver for delicious restaurant tips or tourist hotspots, they know them all!<br/>
          </div>,
        },
        {
          question: 'What happens after I book?',
          answer: 'Once you complete your booking you will receive a confirmation email. Feel free to reach out to our customer service team at hello@getcabbo.com for any specific questions regarding your booking.',
        },
        {
          question: 'What is the cancellation policy?',
          answer: 'You can cancel free of charge up to 24 hours prior to the booking date. No-shows or cancelation requests the day of your ride are not eligible for a refund. ',
        },
        {
          question: 'What should I wear for tours?',
          answer: 'We recommend that you wear comfortable clothing and shoes, and to be equipped with wet weather gear if rain is expected. Please note that religious sites require modest attire - knees and shoulders must be covered.',
        },
        {
          question: 'Local Currency',
          answer: 'We recommend you always keep local currency on you (Euro) as many local merchants, besides CABBO, do not accept cards as payment',
        },
        {
          question: 'Accessibility and Special Needs',
          answer: 'Should anyone in your group have special needs or have advanced accessibility requirements, please specify upon booking and we will make sure to accommodate your needs and customize your ride accordingly.',
        },
        {
          question: 'Terms of service',
            answer: <ul>
            <li>Please note that the CABBO Drivers are not licensed to be your official tour guide at the local attractions and therefore will not accompany you into the sites. You may, however, hire a professional tour guide at an extra cost</li>
            <li>Your CABBO Driver will provide you with their local knowledge and personal recommendations between stops. Since your safety is Cabbo’s top priority, please refrain from distracting the driver during demanding driving conditions</li>
            <li>Your CABBO Driver will drop you off and pick you up as close as possible to your stop whilst always respecting local traffic laws</li>
            <li>Please consult https://getcabbo.com/toc for the full list of Terms and Conditions</li>
          </ul>,
        },
        {
          question: 'Any other questions? ',
          answer: 'Please write us an email, we will take just a few hours to get back to you. hello@getcabbo.com ',
        },
      ]
    }
  }
  render() {
    return (
      <div id={this.state.title}>
        <Destination
          title={this.state.title}
          image={FlorenceImage}
          description={this.state.description}
          story={this.state.story}
          tours={this.state.tours}
          knowledge={this.state.knowledge}
          faq={this.state.faq}
          country={this.state.country}
          reviews={this.state.reviews}
        />
      </div>
    )
  }
}

import React, { Component } from 'react'

import { Container } from 'react-bootstrap'

import '../../assets/styles/_variables.scss';
import './PageHeader.scss'

export default class PageHeader extends Component {
  render() {
    return (
      <Container fluid id='page-header'>
        <Container className="z-index-4">
          <h1 data-aos='fade-right' data-aos-delay='200'>{this.props.title}</h1>
          <p className='lead' data-aos='fade-right' data-aos-delay='200'>{this.props.subtitle}</p>
        </Container>
        <img src={this.props.image} alt={this.props.title + ' | Header Image'} className='image bg-image' data-aos='fade-left' />
      </Container>
    )
  }
}

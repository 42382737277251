import React, { Component } from 'react'

import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import { GCProvider } from '../GCContext'

import * as firebase from "firebase/app";
import "firebase/auth";
import { testAuth } from '../../utils/api'

import AOS from 'aos'
import ReactGA from 'react-ga';

import ScrollToTop from './ScrollToTop'

import Navigation from '../Navigation/Navigation'
import Footer from '../Footer/Footer'

import 'bootstrap/dist/css/bootstrap.min.css';
import 'aos/dist/aos.css'

import '../../assets/styles/style.scss'
import './App.scss'

import {WebpMachine} from "webp-hero"

import Home from '../../routes/Home/Home'
import AboutUs from '../../routes/AboutUs/AboutUs'
// import Partners from '../../routes/Partners/Partners'
import Partners from '../../routes/Partners/Partners_New'
import To from '../../routes/To/To'
import Tour from '../../routes/Tour/Tour'
import ContactUs from '../../routes/ContactUs/ContactUs'
import Terms from '../../routes/Terms/Terms'
import PrivacyPolicy from '../../routes/PrivacyPolicy/PrivacyPolicy'

import Barcelona from '../../routes/Tours/Barcelona'
import Milan from '../../routes/Tours/Milan'
import Rome from '../../routes/Tours/Rome'
import Florence from '../../routes/Tours/Florence'

import BarcelonaTours from '../../routes/Tour/BarcelonaTours'
import FlorenceTours from '../../routes/Tour/FlorenceTours'
import MilanTours from '../../routes/Tour/MilanTours'
import RomeTours from '../../routes/Tour/RomeTours'

function initializeReactGA()
  {
    ReactGA.initialize('UA-137809637-3');
    ReactGA.pageview(window.location.pathname + window.location.search);
  }

var firebaseConfig = {
  apiKey: "AIzaSyCGoiWoR-tc0BFDwswogCsUdccEz3dBXX8",
  authDomain: "getcabbo-1549539564765.firebaseapp.com",
  databaseURL: "https://getcabbo-1549539564765.firebaseio.com",
  projectId: "getcabbo-1549539564765",
  storageBucket: "getcabbo-1549539564765.appspot.com",
  messagingSenderId: "671669896417",
  appId: "1:671669896417:web:753aa69ed8b5726c5c63a0",
  measurementId: "G-G8GQDM82DC"
};

export default class App extends Component {
  constructor() {
    super();

    this.state = {
      userLoggedIn: false,
    }
    
    var webpSupport = window.location.search.indexOf("force") !== -1
				? Promise.resolve(false)
        : undefined
    this.webpMachine = new WebpMachine({webpSupport: webpSupport});
  }

  componentDidMount() {
    AOS.init({
      anchorPlacement: 'center-center',
      duration: 500
    })

    firebase.initializeApp(firebaseConfig);

    firebase.auth().signInAnonymously()
    .then(() => {
      testAuth();
    })
    .catch( (error) => {
      console.log(error.message)
    });

    this.webpMachine.polyfillDocument();

    firebase.auth().onAuthStateChanged( (user) => {
      if (user) {
       this.setState({userLoggedIn: true});
       user.getIdToken().then(function(token) {
        localStorage.setItem('token', token);
       });
      }
     });
  }

  render() {
    return (
      <GCProvider value={{webpMachine: this.webpMachine}}>
        <Router>
          <ScrollToTop>
          <Navigation />
          <Switch>
            <Route exact path='/'><Home /></Route>
            <Route path='/about'><AboutUs /></Route>
            <Route path='/partners'><Partners /></Route>
            <Route exact path='/to'><To /></Route>
            <Route exact path='/tours'><Tour /></Route>
            <Route path='/contact'><ContactUs /></Route>
            <Route path='/terms'><Terms /></Route>
            <Route path='/privacy'><PrivacyPolicy /></Route>
  
            <Route exact path='/to/barcelona'><Barcelona /></Route>
            <Route exact path='/to/milan'><Milan /></Route>
            <Route exact path='/to/rome'><Rome /></Route>
            <Route exact path='/to/florence'><Florence /></Route>
  
            <Route exact path='/to/barcelona/tours'><BarcelonaTours webpMachine={this.webpMachine} /></Route>
            <Route exact path='/to/florence/tours'><FlorenceTours webpMachine={this.webpMachine} /></Route>
            <Route exact path='/to/milan/tours'><MilanTours webpMachine={this.webpMachine} /></Route>
            <Route exact path='/to/rome/tours'><RomeTours webpMachine={this.webpMachine} /></Route>
          </Switch>
  
          <Footer />
          </ScrollToTop>
        </Router>
      </GCProvider>
    )
  }
}

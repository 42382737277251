import React, { Component } from 'react'

import { Accordion, Button, Card, } from 'react-bootstrap'

import './FAQ.scss'

export default class FAQ extends Component {
   constructor(props) {
    super(props)

    this.state = {
      data: []
    }
  }

  componentDidMount() {
    this.setState(state => {
      return {data: this.props.data}
    })
  }

  render() {
    return (
      <>
        <h2 className='title text-center'>Frequently Asked Questions</h2>
        <Accordion defaultActiveKey='0' className='my-5'>
          {this.state.data.map((data, key) =>
  
          <Card>
          <Card.Header>
            <Accordion.Toggle as={Button} variant='link' eventKey={key}>
              {data.question}
            </Accordion.Toggle>
          </Card.Header>
          <Accordion.Collapse eventKey={key}>
            <Card.Body>
              {data.answer}
            </Card.Body>
          </Accordion.Collapse>
        </Card>
  
          
          
          )}
        </Accordion>
      </>
    )
  }
}

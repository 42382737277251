import React from 'react'
import Mobile from './Mobile';
import Web from './Web';


export default function Destination(props) {

    let windowWidth = (window.innerWidth <= 992);
    if (windowWidth) return <Web {...props} />
    else return <Web {...props} />
}

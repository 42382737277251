import React, { Component } from 'react'
import { NavLink } from 'react-router-dom'
import PartnersForm from '../../components/Partners/PartnersForm'

import { Col, Container, Row} from 'react-bootstrap'

import './AboutUs.scss'

import fiveStars from '../../assets/images/hand-star.png'
import mindBlown from '../../assets/images/mind-blown.png'
import inspired from '../../assets/images/heart-bulb.png'


export default class AboutUs extends Component {
  constructor() {
    super()

    this.state = {
        openForm: false,
        points: [
          { title: 'DELUXE', icon: fiveStars, content: 'We want to provide you a service that is not just a transportation service, but we aim to provide an amazing 5 star experience' },
          { title: 'KNOWLEDGEABLE', icon: mindBlown, content: 'When we open a new city we also learn local aspects, we learn for providing you the best local experience.' },
          { title: 'INSPIRED', icon: inspired, content: 'We like to inspire people but also be inspired. Get in touch with us and let us know how we can improve our business.' }
        ]
    }
  }


  openForm = () => {
    this.setState({openForm: true})
  } 

  render() {
    return (
      <div id='about'>
        <Container className='my-5'>
            <Row className="content">
              <Col md={4}>
                <h1 data-aos='fade-up' className="mb-4">About Us</h1>
              </Col>
              <Col>
                <p class="lead" data-aos='fade-up' data-aos-delay='200'>CABBO began life as an airport transfer provider in 2017.</p>
                <p data-aos='fade-up' data-aos-delay='200'>As we grew, we learned what our customers valued and we made it our mission to help customers navigate Italy and soon the world in a simple and effective way.</p>
                <p data-aos='fade-up' data-aos-delay='200'>We have now integrated 100s of partners around the country, choosing among the best drivers so to provide the best service possible.</p>
                <p data-aos='fade-up' data-aos-delay='200'>Our journey has been a special one, and it's not over yet! We’ve helped over 8.000 customers reach their destination and we're ready to help with your next ride.</p>
                <p data-aos='fade-up' data-aos-delay='200'>Whatever your plans, we'll help you arrive happy.</p>
              </Col>
            </Row>
        </Container>
        <Container className='content my-5'>
          <Row className="justify-content-center">
              {this.state.points.map((item, key) =>
              <Col key={key} md={6} className='my-3 d-flex' data-aos='fade-up'>
                <img className="mr-3 align-self-center" src={item.icon} alt={item.title}/>
                <div>
                  <h2 className='title text-center'>{item.title}</h2>
                  <p className="text-center">{item.content}</p>
                </div>
              </Col>
            )}
          </Row>
        </Container>

        <Container className='my-5 text-center'>
          <h6 className='mt-5 text-muted'>Registered Office</h6>
          <p className='mb-5'>20-22 Wenlock Road, London, England, N1 7GU</p>
          <Row>
            <Col>
              <h6 className='text-muted'>Company Number</h6>
              <p className='mb-0'>12384727</p>
            </Col>
            <Col>
              <h6 className='text-muted'>Toll-Free Phone</h6>
              <p className='mb-0'>+44 800 090 3170</p>
            </Col>
          </Row>
        </Container>

        <Container fluid className='bg-light py-3'>
          <Container>
            <Row>
              <Col xs={12} md={6} className='text-center py-4'>
                <h3>Customers</h3>
                <p>
                  Don't just take our word for it.. see what other people have said.
                </p>
                <a exact className='btn btn-primary' href='https://uk.trustpilot.com/review/getcabbo.com' target='_blank' rel="noopener noreferrer">Read our reviews</a>
              </Col>
              <Col xs={12} md={6} className='text-center py-4'>
                <h3>Contact Us</h3>
                <p>
                  Would you like some more information on our services?
                </p>
                <NavLink exact className='btn btn-primary' to='/'>Get in touch</NavLink>
              </Col>
            </Row>
          </Container>
        </Container>

        {this.state.openForm === true ?
            <>
                <div className="popup-overlay d-flex align-items-center justify-content-center" onClick={() => this.setState({openForm: false})}>
                </div>
                <PartnersForm />
                <div className="close-card" onClick={() => this.setState({openForm: false})}>X</div>
            </>
        : <></>}
        
        <div class="fixed-button-container py-3">
          <button className={`btn btn-primary py-3`} onClick={() => this.openForm()}>
               Get In Touch
          </button>
        </div>

      </div>
    )
  }
}

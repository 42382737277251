import React, { Component } from 'react';
import FlorenceImage from '../../assets/images/Florence/1/florencemain2.jpg'
import Tour from '../../components/Tour/Tour'
import NipozzanoCastle from '../../assets/images/Florence/1/Main.webp'
import ValleyOfChianti from '../../assets/images/Florence/2/Main.webp'
import { importAll } from '../../utils/helperFunctions'

const Nipozzano_Castle = importAll(require.context('../../assets/images/Florence/tours/Nipozzano_Castle', false, /\.(png|jpe?g|svg|webp)$/));
const Valley_of_Chianti = importAll(require.context('../../assets/images/Florence/tours/Valley_of_Chianti', false, /\.(png|jpe?g|svg|webp)$/));

class FlorenceTours extends Component {
    constructor(props) {
        super(props);
        this.state = {
            title: 'Choose the experience you like the most and make your stay in Florence unique',
            subtitle: 'The city is all yours!',
            headerImage: FlorenceImage,
            countryName: 'Barcelona',
            countryCode: 'es',
            reviews: [
                {
                  avatar: 'https://via.placeholder.com/150/FFFFFF/FFFFFF/FFFFFF',
                  name:'P.B.H.',
                  stars:'5',
                  text: 'Reliable, punctual, careful and helpful driver. Car roomy, clean and tidy. Recommended.'
                },
                {
                  avatar: 'https://via.placeholder.com/150/FFFFFF/FFFFFF/FFFFFF',
                  name:'Luigi Pedace',
                  stars:'5',
                  text: "I often use Cabbo and I've never been disappointed. Quality service and very professional drivers."
                },
                {
                  avatar: 'https://via.placeholder.com/150/FFFFFF/FFFFFF/FFFFFF',
                  name:'Katie Raymond',
                  stars:'5',
                  text: 'Ideal airport transportation! Could not have been easier. He took our bags and then a smooth ride to our destination'
                },
              ],
            tours: [
              {
                title: 'Nipozzano Castle',
                code:21,
                description: {
                  brief: 'Take a day trip to Nipozzano Castle: the ancient stronghold in defence of Florence, known for its traditional winemaking.             ',
                  full: 'The first evidence of the quality of the Nipozzano wine dates back to the Renaissance, when even the great artists from Donatello and Michelozzo Michelozzi used to buy this wine. By 1855, wine production had grown to be a part of the Nipozzano to the point that an enlightened Frescobaldi ancestor decided to import the cultivation of Cabernet Sauvignon Merlot, Cabernet Franc and Petit Verdot grapevines. Now, the Castles are renowned for the elegant label of the Mormoreto estate.'
                },
                duration: '8 Hours',
                includes: 'English speaking driver and its vehicle available for the duration of the tour, tools, passenger liability insurance that is required by law and fuel.          ',
                excludes: 'Entrance fees to sites, museums and meals.',
                price: '650€ 1-3 persons | 750€ 4-6 persons | 830€ 7-8 persons',
                startingAt: '650€',
                image: NipozzanoCastle,
                slides: Nipozzano_Castle
              },
              {
                title: 'Valley of Chianti Eco-Tour',
                code:22,
                description: {
                  brief: 'A story that began in 1385. A historic link with the Chianti Classico territory. A place where tradition and innovation coexist harmoniously. ',
                  full: <div>
                    A story that began in 1385. A historic link with the Chianti Classico territory. A place where tradition and innovation coexist harmoniously.
                    <br/>
                    <b>A cellar able to tell the past, present and future of the Antinori family.</b><br/>
                    The winery, inaugurated on October 25th 2012, is the confirmation of the historic link between the Antinori family and its homeland. Built entirely with local materials and with great respect for the environment and the Tuscan landscape, it represents a milestone in the history of the family.
                    For the first time in 26 generations, the Antinori family inaugurates a winery designed to accommodate wine lovers, designed to come into direct contact with its production philosophy, made up of passion, patience and the continuous search for quality.<br/>
                    <b>A window on the Chianti Classico</b><br/>
                    The winery overlooks the countryside through a terrace surrounded by the vineyard cultivated with the typical Chianti Classico variety, the Sangiovese, together with the others that are historically placed side by side: the Canaiolo, the Ciliegiolo, the Colorino, the black Malvasia, the Mammolo. A small part is also dedicated to international vines, the Cabernet Sauvignon and Franc.<br/>
                    <b>Innovative architecture</b><br/>
                    Conceived to have a low environmental impact and high energy savings, the winery is a fascinating and unusual place.
                    <br/>
                    Virtually invisible from the outside, if not for two long horizontal “cracks” that cross the hill and correspond to the front of the building, its distinctive helical sign is the dramatic spiral staircase that connects the 3 floors of the structure.
                    <br/>
                    The Antinori winery in the Chianti Classico area is part of Toscana Wine Architecture, a circuit that combines designer and wine cellars, signed by the great masters of contemporary architecture.<br/>
                    <b>A sacred place of silence</b><br/>
                    The cellar has been designed to allow winemaking "by gravity" and to guarantee the ideal temperature for wine production and storage in a natural way.
                    "It is a sacred place of silence, temple of ancient rites of grapes, but at the same time it is a place of production that must meet certain quality requirements."
                    Cit. Marco Casamonti, architect of Antinori in the Chianti Classico.
                  </div>
                },
                duration: '8 Hours',
                includes: 'English speaking driver and its vehicle available for the duration of the tour,tools, passenger liability insurance that is required by law and fuel.',
                excludes: 'Entrance fees to sites, museums and meals.',
                price: '650€ 1-3 persons | 750€ 4-6 persons | 830€ 7-8 persons',
                startingAt: '650€',
                image: ValleyOfChianti,
                slides: Valley_of_Chianti
              },
            ],
            faq: [
              {
                question: 'What is included in the price',
                answer: <ul>
                  <li>Pickup and dropoff</li>
                  <li>Free wifi onboard</li>
                  <li>All taxes, fees and handling charges</li>
                  <li>Water and Snacks</li>
                  <li>Well-maintained car with an English-speaking driver</li>
                </ul>,
              },
              {
                question: 'What is not included in the price',
                answer: <ul>
                  <li>Food and drinks, not specified in tour itinerary</li>
                  <li>Entrance fees to attractions</li>
                  <li>Official tour guide (you can include one after the booking)</li>
                  <li>Gratuities</li>
                </ul>,
              },
              {
                question: 'Pick up from Firenze-Peretola (Florence)',
                answer: <div>
                  Otherwise known as Florence Airport, Peretola (FLR):<br/>
                  1) Pick up your luggage and leave the baggage claim area;<br/>
                  2) The Driver will have checked your flight number so he will be waiting for you at the arrivals area;<br/>
                  3) By the Arrivals, you will find a lot of drivers. Look for the one holding the signboard with the name you indicated in the booking form;<br/>
                  4) Let the driver help you with your luggage;<br/>
                  5) Enjoy your trip! Remember that Cabbo Drivers know all the secrets of the city.
                </div>,
              },
              {
                question: 'Pick up from Pisa Airport',
                answer: <div>
                  Otherwise known as Galileo Galilei Airport, (PSA):<br/>
                  1) Pick up your luggage and leave the baggage claim area;<br/>
                  2) The Driver will have checked your flight number so he will be waiting for you at the arrivals area;<br/>
                  3) By the Arrivals, you will find a lot of drivers. Look for the one holding the signboard with the name you indicated in the booking form;<br/>
                  4) Let the driver help you with your luggage;<br/>
                  5) Enjoy your trip! Remember that Cabbo Drivers know all the secrets of the city.
                </div>,
              },
              {
                question: 'Pick up from Stazione Firenze Santa Maria Novella',
                answer: <div>

                1) Walk out from the Platforms Area<br/>
                2) Your Cabbo Ride will be waiting for you in front of "Farmacia Santa Maria Novella" (BIG GREEN CROSS), holding a signboard with the name you indicated on the Booking Form.<br/>
                3) Let the driver help you with your bags;<br/>
                4) Enjoy your trip! Remember that Cabbo Drivers know all the secrets of the city.

                </div>,
              },
              {
                question: 'Pick up from Livorno/La Spezia Port',
                answer: <div>
                1) Get off the ship;<br/>
                2) Search among the drivers the one holding a signboard with the name you specified in the Booking Form. All of our drivers are equipped with a license to come get you right as you disembark;<br/>
                3) Let the driver help you with your bags;<br/>
                4) Enjoy your trip! Remember that Cabbo Drivers know all the secrets of the city.
                </div>,
              },
              {
                question: 'Pick up from another address',
                answer: <div>
                  1) The driver will meet you outside of the specified location, you will receive an SMS notification if you don’t meet by the indicated time.<br/>
                  2) Let the driver help you with your bags;<br/>
                  3) Enjoy your ride. Don’t forget to ask your Cabbo Driver for delicious restaurant tips or tourist hotspots, they know them all!<br/>
                </div>,
              },
              {
                question: 'What happens after I book?',
                answer: 'Once you complete your booking you will receive a confirmation email. Feel free to reach out to our customer service team at hello@getcabbo.com for any specific questions regarding your booking.',
              },
              {
                question: 'What is the cancellation policy?',
                answer: 'You can cancel free of charge up to 24 hours prior to the booking date. No-shows or cancelation requests the day of your ride are not eligible for a refund. ',
              },
              {
                question: 'What should I wear for tours?',
                answer: 'We recommend that you wear comfortable clothing and shoes, and to be equipped with wet weather gear if rain is expected. Please note that religious sites require modest attire - knees and shoulders must be covered.',
              },
              {
                question: 'Local Currency',
                answer: 'We recommend you always keep local currency on you (Euro) as many local merchants, besides CABBO, do not accept cards as payment',
              },
              {
                question: 'Accessibility and Special Needs',
                answer: 'Should anyone in your group have special needs or have advanced accessibility requirements, please specify upon booking and we will make sure to accommodate your needs and customize your ride accordingly.',
              },
              {
                question: 'Terms of service',
                  answer: <ul>
                  <li>Please note that the CABBO Drivers are not licensed to be your official tour guide at the local attractions and therefore will not accompany you into the sites. You may, however, hire a professional tour guide at an extra cost</li>
                  <li>Your CABBO Driver will provide you with their local knowledge and personal recommendations between stops. Since your safety is Cabbo’s top priority, please refrain from distracting the driver during demanding driving conditions</li>
                  <li>Your CABBO Driver will drop you off and pick you up as close as possible to your stop whilst always respecting local traffic laws</li>
                  <li>Please consult https://getcabbo.com/toc for the full list of Terms and Conditions</li>
                </ul>,
              },
              {
                question: 'Any other questions? ',
                answer: 'Please write us an email, we will take just a few hours to get back to you. hello@getcabbo.com ',
              },
            ]
        }
    }
    render() {
        return (
            <div>
                <Tour
                    title={this.state.title}
                    subtitle={this.state.subtitle}
                    image={this.state.headerImage}
                    faq={this.state.faq}
                    reviews={this.state.reviews}
                    tours={this.state.tours}
                />
            </div>
        );
    }
}

export default FlorenceTours;

import React, { Component } from 'react';
import MilanImage from '../../assets/images/Milan.png'
import Tour from '../../components/Tour/Tour'
import ComoAndLugano from '../../assets/images/Milan/1/Main.webp'
import LagoMaggiore from '../../assets/images/Milan/2/Main.webp'
import SirmioneVerona from '../../assets/images/Milan/3/Main.webp'
import Venice from '../../assets/images/Milan/4/Main.webp'
import { importAll } from '../../utils/helperFunctions'

const comoLuganoSlides = importAll(require.context('../../assets/images/Milan/tours/Como_Lugano', false, /\.(png|jpe?g|svg|webp)$/));
const lagoMaggioreSlides = importAll(require.context('../../assets/images/Milan/tours/Lago_Maggiore_and_Lago_d_Orta', false, /\.(png|jpe?g|svg|webp)$/));
const sirmioneVeronaSlides = importAll(require.context('../../assets/images/Milan/tours/Sirmione_Verona', false, /\.(png|jpe?g|svg|webp)$/));
const veniceSlides = importAll(require.context('../../assets/images/Milan/tours/Venice', false, /\.(png|jpe?g|svg|webp)$/));

class MilanTours extends Component {
    constructor(props) {
        super(props);
        this.state = {
            title: 'Choose the experience you like the most and make your stay in Milan unique',
            subtitle: 'The city is all yours!',
            headerImage: MilanImage,
            countryName: 'Barcelona',
            countryCode: 'es',
            reviews: [
                {
                  avatar: 'https://via.placeholder.com/150/FFFFFF/FFFFFF/FFFFFF',
                  name:'P.B.H.',
                  stars:'5',
                  text: 'Reliable, punctual, careful and helpful driver. Car roomy, clean and tidy. Recommended.'
                },
                {
                  avatar: 'https://via.placeholder.com/150/FFFFFF/FFFFFF/FFFFFF',
                  name:'Luigi Pedace',
                  stars:'5',
                  text: "I often use Cabbo and I've never been disappointed. Quality service and very professional drivers."
                },
                {
                  avatar: 'https://via.placeholder.com/150/FFFFFF/FFFFFF/FFFFFF',
                  name:'Katie Raymond',
                  stars:'5',
                  text: 'Ideal airport transportation! Could not have been easier. He took our bags and then a smooth ride to our destination'
                },
              ],
            tours: [
              {
                title: 'Como and Lugano',
                code:31,
                description: {
                  brief: ' A one-day tour departing from Milan and visiting Como and Lugano. Two places full of charm and appeal.',
                  full: <div>
                    A one-day tour departing from Milan to visit the city of Como and Lugano lasting about 8 hours. The opportunity to discover unique views and landscapes.<br/>
                    Lugano is a city in southern Switzerland in the Italian-speaking canton of Ticino bordering Italy Lugano lies at the edge of Lake Lugano , which is situated between the lakes Lago Maggiore and Lago di Como, south of the Alps. It lies at the heart of the Sottoceneri, that part of the canton of Ticino that lies south of the Monte Ceneri Pass.  <br/>
                    Lake Como , is a lake of glacial origin in Lombardy, Italy. It has an area of 146 square kilometres (56 sq mi), making it the third-largest lake in Italy, after Lake Garda and Lake Maggiore. At over 400 metres (1,300 ft) deep, it is the fifth deepest lake in Europe, and the deepest outside Norway; the bottom of the lake is more than 200 metres (660 ft) below sea level.  <br/>
                    In 2014, The Huffington Post called it the most beautiful lake in the world for its microclimate and environment with prestigious villas and villages.  <br/>
                    Lake Como has been a popular retreat for aristocrats and wealthy people since Roman times, and a very popular tourist attraction with many artistic and cultural gems. It has many villas and palaces such as Villa Olmo, Villa Serbelloni, and Villa Carlotta. Many famous people have had and have homes on the shores of Lake Como.
                  </div>
                },
                duration: '8 Hours',
                includes: 'English speaking driver and its vehicle available for the duration of the tour, tools, passenger liability insurance that is required by law and fuel.          ',
                excludes: 'Entrance fees to sites, museums and meals.',
                price: '850€ 1-3 persons | 900€ 4-6 persons | 990€ 7-8 persons',
                startingAt: '850€',
                image: ComoAndLugano,
                slides: comoLuganoSlides
              },
              {
                title: 'Lago Maggiore e Lago D’Orta',
                code:32,
                description: {
                  brief: ' This tour is recommended for lovers of natural landscapes and good Italian Lombard cuisine',
                  full: <div>
                    A one-day tour departing from Milan and visiting Lake Maggiore and D'Orta. Average duration of the tour 10 hours, where you can discover charming towns, landscapes and places.  <br/>
                    Lake Orta, in Cusio, is located west of Lake Maggiore: it is the westernmost among the pre-Alpine lakes of Northern Italy, located entirely in Piedmont. The eastern side presents the gentle slopes of the hills that separate from Lake Maggiore and culminate at 1491 meters of Mount Mottarone. The western shores are instead more impervious and steep: the hills leave space in the mountains of Valstrona and the nearby Valsesia.<br/>

                    Lake Orta: the numbers The lake has an area of ​​18.2 square kilometers, stretching from north to south for 13.4 km and with a maximum width of 2.5 km. The climate is mite, the coasts and the very picturesque surroundings, rich in woods, crops, fervent of industrial and tourist activity. The villages overlooking the mirror of the Lake, generally rich in history, are today subdivided, from the administrative point of view, by the Province of Novara and the Province of the VCO, or by the Verbano-Cusio-Ossola, headed by Verbania.  <br/>
                    The area and the towns of Lake OrtaIn the central area of ​​the eastern shore there is the village that over time has given its name to the Lake, once called Cusio, as it was Cusio, and it is still today, the area where it is located. Orta San Giulio, a renowned and romantic holiday resort, is dominated from behind by its Sacro Monte, with the 20 chapels frescoed by Morazzone. Opposite is the Island of San Giulio, with the Basilica of the same name of the century. IV, rebuilt in the IX and XI centuries  <br/>
                    The ancient roots of the area, its landscapes and its picturesque villages, sources of inspiration for writers, painters and directors, have not prevented these places of change from also artisanal and then industrial traditions. Today the "district of housewares" stands out, centered in the area of ​​Omegna, the northern end of Lake Orta, and the "district of the tap", built between San Murizio d'Opaglio and Gozzano.

                  </div>
                },
                duration: '10 Hours',
                includes: 'English speaking driver and its vehicle available for the duration of the tour, tools, passenger liability insurance that is required by law and fuel.          ',
                excludes: 'Entrance fees to sites, museums and meals.',
                price: '900€ 1-3 persons | 970€ 4-6 persons | 1070€ 7-8 persons',
                startingAt: '900€',
                image: LagoMaggiore,
                slides: lagoMaggioreSlides
              },
              {
                title: 'Sirmione e Verona',
                code:33,
                description: {
                  brief: ' A one-day tour departing from Milan and visiting Sirmione and Verona. Two places of culture and historical architecture.',
                  full: <div>
                    Verona is a city in Veneto, northern Italy, one of the seven provincial capitals in the region. It is one of the main tourist destinations in north-eastern Italy, thanks to its artistic heritage, several annual fairs, shows and operas, such as the lyrical season in the Arena, the ancient amphitheatre built by the Romans.  <br/>
                    Verona owes its historical and economical importance to its geographical location, in a loop of the Adige River near Lake Garda. Because of this position, the areas saw regular floodings until 1956, when the Mori-Torbole tunnel was constructed, providing 500 cubic meters of discharge from the Adige river to Lake Garda when there was danger of flooding. The tunnel reduced the risk of flooding from once every seventy years to once every two centuries.
                      <br/>
                    Sirmione, famous since antiquity for its generous thermal waters, stretches along a thin peninsula that protrudes  into Lake Garda for about 4 km. It’s one of the most beautiful places of the lake, from which one can enjoy the splendid views of both shores: the villas arose since roman times, including poet Catullo’s one, who celebrated the beauty of these places in his verses. Nature and history: from Scaliger Castle to  the archaeological site, located on the end of the  peninsula, Sirmione contains matchless treasures of art and history.
                  </div>
                },
                duration: '10 Hours',
                includes: 'English speaking driver and its vehicle available for the duration of the tour, tools, passenger liability insurance that is required by law and fuel.          ',
                excludes: 'Entrance fees to sites, museums and meals.',
                price: '900€ 1-3 persons | 970€ 4-6 persons | 1070€ 7-8 persons',
                startingAt: '900€',
                image: SirmioneVerona,
                slides: sirmioneVeronaSlides
              },
              {
                title: 'Venezia',
                code:34,
                description: {
                  brief: ' This tour is designed for passengers that want to enjoy a day admiring the incomparable and immense artistic and cultural heritage that Venezia has to offer.            ',
                  full: <div>
                    A one-day tour departing from Milan and visiting Padua and Venice. Average duration of the tour 12 hours. Two splendid cities, rich in history and full of charm.  <br/>
                    Venice is a city in northern Italy characterized by the fact that in winter the roads are often flooded by water, because it is a town on the coast. this phenomenon a lot of problems for those working in the flood areas, but this city has become famous enhancing tourism, and has also provided a fruitful source of income, as it is born of gondola profession, people in exchange for money transporting tourists in boats called gondolas in the streets.  <br/>
                    three or four times a year in Venice arrives a  widespread flooding and and very rarely is an increase of 140 centimeters of water which are enough to flood 90% of the city

                  </div>
                },
                duration: '12 Hours',
                includes: 'English speaking driver and its vehicle available for the duration of the tour, tools, passenger liability insurance that is required by law and fuel.          ',
                excludes: 'Entrance fees to sites, museums and meals.',
                price: '930€ 1-3 persons | 980€ 4-6 persons | 1090€ 7-8 persons',
                startingAt: '930€',
                image: Venice,
                slides: veniceSlides
              },
            ],
            faq: [
              {
                question: 'What is included in the price',
                answer: <ul>
                  <li>Pickup and dropoff</li>
                  <li>Free wifi onboard</li>
                  <li>All taxes, fees and handling charges</li>
                  <li>Water and Snacks</li>
                  <li>Well-maintained car with an English-speaking driver</li>
                </ul>
              },
              {
                question: 'What is not included in the price',
                answer: <ul>
                  <li>Food and drinks, not specified in tour itinerary</li>
                  <li>Entrance fees to attractions</li>
                  <li>Official tour guide (you can include one after the booking)</li>
                  <li>Gratuities</li>
                </ul>
              },
              {
                question: 'Pick up from Malpensa Airport',
                answer: <div>
                  Otherwise known as Malpensa Milan Airport (MXP)<br/>

                  1) Pick up your luggage and leave the baggage claim area;<br/>
                  2) The Driver will have checked your flight number so he will be waiting for you at the arrivals area;<br/>
                  3) By the Arrivals, you will find a lot of drivers. Look for the one holding the signboard with the name you indicated in the booking form;<br/>
                  4) Let the driver help you with your luggage;<br/>
                  5) Enjoy your trip! Remember that Cabbo Drivers know all the secrets of the city.

                </div>
              },
              {
                question: 'Pick up from Linate Airport',
                answer: <div>
                  Otherwise known as Milan Linate Airport (LIN)<br/>

                  1) Pick up your luggage and leave the baggage claim area;<br/>
                  2) The Driver will have checked your flight number so he will be waiting for you at the arrivals area;<br/>
                  3) By the Arrivals, you will find a lot of drivers. Look for the one holding the signboard with the name you indicated in the booking form;<br/>
                  4) Let the driver help you with your luggage;<br/>
                  5) Enjoy your trip! Remember that Cabbo Drivers know all the secrets of the city.

                </div>
              },
              {
                question: 'Pick up from another address',
                answer: <div>
                  1) The driver will meet you outside of the specified location, you will receive an SMS notification if you don’t meet by the indicated time.<br/>
                  2) Let the driver help you with your bags;<br/>
                  3) Enjoy your ride. Don’t forget to ask your Cabbo Driver for delicious restaurant tips or tourist hotspots, they know them all!
                </div>
              },
              {
                question: 'What happens after I book?',
                answer: 'Once you complete your booking you will receive a confirmation email. Feel free to reach out to our customer service team at hello@getcabbo.com for any specific questions regarding your booking.'
              },
              {
                question: 'What is the cancellation policy?',
                answer: 'You can cancel free of charge up to 24 hours prior to the booking date. No-shows or cancelation requests the day of your ride are not eligible for a refund. '
              },
              {
                question: 'What should I wear for tours?',
                answer: 'We recommend that you wear comfortable clothing and shoes, and to be equipped with wet weather gear if rain is expected. Please note that religious sites require modest attire - knees and shoulders must be covered.'
              },
              {
                question: 'Local Currency',
                answer: 'We recommend you always keep local currency on you (Euro) as many local merchants, besides CABBO, do not accept cards as payment'
              },
              {
                question: 'Accessibility and Special Needs',
                answer: 'Should anyone in your group have special needs or have advanced accessibility requirements, please specify upon booking and we will make sure to accommodate your needs and customize your ride accordingly.'
              },
              {
                question: 'Terms of service',
                answer: <ul>
                  <li>Please note that the CABBO Drivers are not licensed to be your official tour guide at the local attractions and therefore will not accompany you into the sites. You may, however, hire a professional tour guide at an extra cost</li>
                  <li>Your CABBO Driver will provide you with their local knowledge and personal recommendations between stops. Since your safety is Cabbo’s top priority, please refrain from distracting the driver during demanding driving conditions</li>
                  <li>Your CABBO Driver will drop you off and pick you up as close as possible to your stop whilst always respecting local traffic laws</li>
                  <li>Please consult https://getcabbo.com/toc for the full list of Terms and Conditions</li>
                </ul>
              },
              {
                question: 'Any other questions? ',
                answer: 'Please write us an email, we will take just a few hours to get back to you. hello@getcabbo.com '
              },
            ]
        }
    }
    render() {
        return (
            <div>
                <Tour
                    title={this.state.title}
                    subtitle={this.state.subtitle}
                    image={this.state.headerImage}
                    faq={this.state.faq}
                    reviews={this.state.reviews}
                    tours={this.state.tours}
                />
            </div>
        );
    }
}

export default MilanTours;

import React, { Component } from 'react';
import { Card, Form, Container } from 'react-bootstrap'
import makeApiRequest from '../../utils/api.js'
import Loader from "../Loader/Loader"

import './partners-form.scss'

class PartnersForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            name: '',
            email: '',
            phone: '',
            selectedCity: '',
            selectedRef: 'Social Media',
            isCompleted: false,
            isSubmitting: false,
            isSubmitted: false,
            progressVisible: false,
            errors: {
                name: '',
                email: '',
                phone: '',
                city: '',
            },
            cities: ['Barcenona', 'Florence', 'Milan', 'Rome'],
            referrals: ['Social Media', 'Event', 'Guest', 'Someone referred Cabbo to me']
        }
    }

    sendMail() {
        this.setState({isSubmitting: true});
        console.log('submitting...')
        window.Email.send({
          Host : "getcabbo.com",
          Username : "mailhook@getcabbo.com",
          Password : "Y68GkE@]BfUW",
          To : 'mailhook@getcabbo.com',
          From : "mailhook@getcabbo.com",
          Subject : "Partnership Request From " + this.state.name,
          Body : "<div>Hello,<br/>This is an email regarding a partnership <br/>" + "name: " + this.state.name + ".<br/>The details are as follows :" + "<br/> Phone Number: " + this.state.phone + "<br/> Email Address: " + this.state.email + "How did you hear about us:" + this.state.selectedRef + "City:" + this.state.selectedCity + "</div>"
       }).then((message) => {
            if(message){
                console.log(message)
             this.setState({
                name: '',
                email: '',
                phone: '',
                isCompleted: true,
                isSubmitting: false
             })
            }
          });
      }

    submitForm = async (payload) => {
        this.setState({isSubmitting: true});
        const res = await makeApiRequest('/contact?origin=getcabbo&name=partners-form', payload, {method: "POST"});
        if (res) {
            this.setState({
                name: '',
                email: '',
                phone: '',
                isCompleted: true,
                isSubmitting: false
            })
            setTimeout(() => this.props.closeForm(), 500);
        }
    }

    checkErrors() {
        const validEmailRegex = RegExp(/^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i);
        const validPhoneRegex = RegExp(/^\s*(?:\+?(\d{1,3}))?[-. (]*(\d{3})[-. )]*(\d{3})[-. ]*(\d{4})(?: *x(\d+))?\s*$/i);
        var errorExists = false;

        if (this.state.name === '') {
            this.setState({errors: {name: 'Enter a name' }});
            errorExists = true;
        }
        if (!validEmailRegex.test(this.state.email)) {
            this.setState(prevState => ({errors: {...prevState.errors, email: 'Email is not valid'}}));
            errorExists = true;
        }
        if (!validPhoneRegex.test(this.state.phone)) {
            this.setState(prevState => ({errors: {...prevState.errors, phone: 'Enter a valid phone number'}}));
            errorExists = true;
        }
        if (!this.state.cities.includes(this.state.selectedCity)) {
            this.setState(prevState => ({errors: {...prevState.errors, city: 'Please select a city'}}));   
            errorExists = true;
        }

        return errorExists;
    }

    handleSubmit(e) {
        e.preventDefault();
        this.setState({isSubmitted: false, isCompleted: false, errors: {name: '', email: '', phone: '', city: ''}})
        if (!this.checkErrors()) {
            const payload = {
                "name": this.state.name,
                "email": this.state.email, 
                "phone": this.state.phone, 
                "city": this.state.selectedCity, 
                "How did you hear about us?": this.state.selectedRef
            }
            // this.sendMail();
            this.submitForm(payload);
        }
    }

    handleChange(param, e) {
        this.setState({[param]: e.target.value});
    }

    componentDidUpdate() {
        if (this.state.isSubmitting && !this.state.progressVisible) {
            this.setState({progressVisible: true});
        }

        if (this.state.isCompleted && !this.state.isSubmitted) {
            setTimeout(() => {
                this.setState({progressVisible: false, isSubmitted: true})
            }, 1500)
        }
    }

    render() {
        const {name, email, phone, selectedCity, selectedRef, cities, referrals, progressVisible, isCompleted, isSubmitting} = this.state;
        return (
            <>
            {progressVisible === true ?
               <div className="popup-overlay background-blur d-flex flex-column align-items-center justify-content-center">
                    <Loader completed={isCompleted} />
                    <h1 className="text-white">{isSubmitting ? 'Submitting...' : 'Submitted'}</h1>
                </div>
                : 
                <Card className="partners-form show-above rounded bg-white d-flex flex-column p-4">
                <Card.Body className="p-0">
                      <Form as={Container}>
                          <Card.Title>Get In Touch</Card.Title>
                          <p>Get your page ready to offer Cabbo Pickups to your guests.</p>
                          <Form.Row>
                              <Form.Control type="text" value={name} name="name" id="name" placeholder="First & Last name" onChange={this.handleChange.bind(this, 'name')}/>
                              {this.state.errors.name !== '' ? <span className="error text-danger">{this.state.errors.name}</span> : <></>}
                          </Form.Row>
                          <Form.Row>
                              <Form.Control type="text" value={email} name="email" id="email" placeholder="Contact Email" onChange={this.handleChange.bind(this, 'email')}/>
                              {this.state.errors.email !== '' ? <span className="error text-danger">{this.state.errors.email}</span> : <></>}
                          </Form.Row>
                          <Form.Row>
                              <Form.Control type="tel" value={phone} name="phone" id="phone" placeholder="Phone number" onChange={this.handleChange.bind(this, 'phone')}/>
                              {this.state.errors.phone !== '' ? <span className="error text-danger">{this.state.errors.phone}</span> : <></>}
                          </Form.Row>
                          <Form.Row>
                              <Form.Group className="d-flex flex-column">
                                  <label htmlFor="referral" className="mb-0">How did you hear about us?</label>
                                  <Form.Control as="select" name="referral" value={selectedRef} id="referral" onChange={this.handleChange.bind(this, 'selectedRef')}>
                                      {referrals.map((referral, i) => {
                                          return (
                                              <option key={i} value={referral}>{referral}</option>
                                          )
                                      })}
                                  </Form.Control>
                              </Form.Group>
                          </Form.Row>
                          <Form.Row>
                              <Form.Group>
                                  <Form.Control as="select" name="city" value={selectedCity} id="city" onChange={this.handleChange.bind(this, 'selectedCity')}>
                                      <option value="" hidden>Select a city</option>
                                      {cities.map((city, i) => {
                                          return (
                                              <option key={i} value={city}>{city}</option>
                                          )
                                      })}
                                  </Form.Control>
                                   {this.state.errors.city !== '' ? <span className="error text-danger">{this.state.errors.city}</span> : <></>}                     
                              </Form.Group>
                          </Form.Row>
                          <input type="submit" value="submit" className="btn btn-primary" onClick={(e) => this.handleSubmit(e)}/>
                      </Form>
                  </Card.Body>
              </Card>
            }
            </>
        );
    }
}

export default PartnersForm;

import React, { Component } from 'react'

import Placeholder from '../../assets/images/NewHeader.jpg'

import { Container, Row } from 'react-bootstrap'

import Nizza from '../../assets/images/Nizza.jpg'
import Madrid from '../../assets/images/Madrid.jpg'
import London from '../../assets/images/London.jpg'
import Paris from '../../assets/images/Paris.jpg'

import './Cities.scss'

export default class Cities extends Component {
  constructor(props) {
    super(props)
    this.state = {
      cities: [
        { name: 'Nizza', image: Nizza, delay: '0' },
        { name: 'Madrid', image: Madrid, delay: '200' },
        { name: 'London', image: London, delay: '400' },
        { name: 'Paris', image: Paris, delay: '600' },
      ]
    }
  }
  render() {
    return (
      <Container className='cities my-5'>
        <h2 className='title'>We're expanding soon to...</h2>
        <Row>
          {this.state.cities.map((item, key) =>
            <div className='col-md-3 mt-4' id='city' data-aos='fade-up' data-aos-delay={item.delay}>
              <div className='content'>{item.name}</div>
              <img src={item.image} alt={item.name} />
            </div>
          )}
        </Row>
      </Container>
    )
  }
}

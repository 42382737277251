import React, { Component } from 'react'

import { Col, Container, Row } from 'react-bootstrap'

import './UniqueSalesPoints.scss'

import HandHeartIcon from '../../assets/images/hand-heart-icon.svg'
import MeetIcon from '../../assets/images/meet-icon.svg'
import NotificationIcon from '../../assets/images/notification-icon.svg'
import fixedRate from '../../assets/images/fixed-rate.svg'
import efficient from '../../assets/images/efficient.svg'
import VanIcon from '../../assets/images/quality.svg'
import safetyFirst from '../../assets/images/ppe.svg'

export default class UniqueSalesPoints extends Component {
  constructor(props) {
    super(props)
    this.state = {
      points: [
        { title: 'Quality Vehicles', icon: VanIcon, content: 'We guarantee a top quality service on board of first class cars and vehicles, sanitized and equipped with all comforts.' },
        { title: 'Meet and Greet', icon: MeetIcon, content: 'Your English-speaking Cabbo Driver will meet you at your preferred pickup spot with a smile and a helpful hand.' },
        { title: 'Free Cancellation', icon: HandHeartIcon, content: `Sometimes things don't pan out as you had quite anticipated. We offer a flexible cancelation policy to adapt to your last minute changes.` },
        { title: '24 Hour Customer Service', icon: NotificationIcon, content: 'We provide free round the clock customer service, so that you can spend less time worrying and more time enjoying your travels.' },
        { title: 'Efficient', icon: efficient, content: 'Local transportation services are often expensive and unreliable. Cabbo provides an accessible solution right at your fingertip.' },
        { title: 'Fixed Rates & Reliable Transfers', icon: fixedRate, content: 'Our professional drivers speak excellent English and assure a smooth, safe and relaxing drive.' },
        { title: 'Safety First', icon: safetyFirst, content: 'Now more then ever we care about the safety of our clients and our driver partners. We inspect our vehicles daily and enforce stringent health and quality standards.' }
      ]
    }
  }

  render() {
    return (
      <Container fluid className='usp py-5 bg-white position-relative'>
        <Container>
          <Row className="justify-content-center">
            {this.state.points.map((item, key) =>
              <Col xs={6} className='my-3 d-flex' data-aos='fade-up'>
                <img src={item.icon} alt={item.title} className="mr-3"/>
                <div>
                  <h2 className='title'>{item.title}</h2>
                  <p>{item.content}</p>
                </div>
              </Col>
            )}
          </Row>
        </Container>
      </Container>
    )
  }
}

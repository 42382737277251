import React, { Component } from 'react';
import BarcelonaImage from '../../assets/images/Barcelona.png'
import Tour from '../../components/Tour/Tour'
import HalfdayinBarcellona from '../../assets/images/Barcelona/1/Main.webp'
import PrivatetourtoMonseratfromBarcellona from '../../assets/images/Barcelona/2/Main.webp'
import Sitges_Private5HoursTourfromBarcelona from '../../assets/images/Barcelona/3/Main.webp'
import { importAll } from '../../utils/helperFunctions'

const halfDay = importAll(require.context('../../assets/images/Barcelona/tours/half_day', false, /\.(png|jpe?g|svg|webp)$/));
const private_5_hour_tour = importAll(require.context('../../assets/images/Barcelona/tours/sitges_private_5_hour_tour', false, /\.(png|jpe?g|svg|webp)$/));
const private_tour_to_monteserrat = importAll(require.context('../../assets/images/Barcelona/tours/private_tour_to_monteserrat', false, /\.(png|jpe?g|svg|webp)$/));

class BarcelonaTours extends Component {
    constructor(props) {
        super(props);
        this.state = {
            title: 'Choose the experience you like the most and make your stay in Barcelona unique',
            subtitle: 'The city is all yours!',
            headerImage: BarcelonaImage,
            countryName: 'Barcelona',
            countryCode: 'es',
            reviews: [
                {
                  avatar: 'https://via.placeholder.com/150/FFFFFF/FFFFFF/FFFFFF',
                  name:'P.B.H.',
                  stars:'5',
                  text: 'Reliable, punctual, careful and helpful driver. Car roomy, clean and tidy. Recommended.'
                },
                {
                  avatar: 'https://via.placeholder.com/150/FFFFFF/FFFFFF/FFFFFF',
                  name:'Luigi Pedace',
                  stars:'5',
                  text: "I often use Cabbo and I've never been disappointed. Quality service and very professional drivers."
                },
                {
                  avatar: 'https://via.placeholder.com/150/FFFFFF/FFFFFF/FFFFFF',
                  name:'Katie Raymond',
                  stars:'5',
                  text: 'Ideal airport transportation! Could not have been easier. He took our bags and then a smooth ride to our destination'
                },
              ],
            tours: [
              {
                title: 'Barcelona: Private City Tour with Driver and Guide',
                code: 11,
                description: {
                  brief: 'Book a vehicle with a local guide and enjoy the flexibility of your private tour.',
                  full: 'Discover Barcelona in comfort. Enjoy the maximum flexibility and mobility with private transportation that includes a driver and additional personal guide at your disposition. During this private city tour, you will be introduced to Barcelona, passing by to the most popular squares in town. In some locations, you have the opportunity to have a little walk getting in direct touch with the sights. Choose a pickup point anywhere in Barcelona, such as your hotel, airport, or cruise terminal. Use this opportunity to make the most out of your visit!'
                },
                duration: '5 hours',
                includes: 'English speaking driver and its vehicle available for the duration of the tour, tools, passenger liability insurance that is required by law and fuel.          ',
                excludes: 'Entrance fees to sites, museums and meals.',
                price: '250€ 1-3 persons | 350€ 4-6 persons | 425€ 7-8 persons',
                startingAt: '250€',
                image: HalfdayinBarcellona,
                slides: halfDay
              },
              {
                title: 'Private tour to Monserrat',
                code: 12,
                description: {
                  brief: 'Escape Barcelona on a private half-day excursion to the must-see Montserrat Mountain and Monastery. Feast your eyes on the impressive Basilica, the breathtaking views and remarkable rock formations. Enjoy some Catalan snacks and drinks along the way.            ',
                  full: "Escape the hustle and bustle of beautiful Barcelona for the afternoon as you enjoy a private half-day tour to the stunning Montserrat Mountain. Poised atop the mountain of Montesrrat, the Benedictine Abbey of Santa Maria de Montserrat is a must-see for those interested in religious history and breathtaking views. Built 1,000 years ago, the Abbey remains one of the most popular destinations for Christians worldwide, with many choosing to go on a pilgrimage here each year. Admire the wonderful mountain views and the impressive Basilica at the top of the mountain, considered by some as the site of the Holy Grail. If tantalizing your historical knowledge isn't enough then feast your eyes on the picturesque landscape situated around the monastery as you make your way to the top of the Montserrat.            Be sure to capture the sprawling scenery and spectacular rock formations on camera, these sights can't be found every day! A trip to the area's traditional food markets for the chance to sample some local delicacies is a tasty way to conclude your tour."
                },
                duration: '5 hours',
                includes: 'English speaking driver and its vehicle available for the duration of the tour, tools, passenger liability insurance that is required by law and fuel. ',
                excludes: 'Entrance fees to sites, museums and meals.',
                price: '450€ 1-3 persons | 550€ 4-6 persons | 650€ 7-8 persons',
                image: PrivatetourtoMonseratfromBarcellona,
                startingAt: '450€',
                slides: private_tour_to_monteserrat
              },
              {
                title: 'Sitges: Private 5-Hours Tour from Barcelona',
                code: 13,
                description: {
                  brief: ' Take a day trip from Barcelona to the beautiful coastal village of Sitges, and enjoy the comfort of private transportation on the 45-minute drive. Discover the attractions of the old town on a guided tour and take in stunning sea and mountain views.            ',
                  full: 'On this 5-hour excursion you’ll enjoy private round-trip transportation between your accommodation in Barcelona and Sitges, as well as the services of an official private tour guide, luxury vehicle and separate chauffeur. In just 45 minutes you’ll arrive in Sitges, the lovely coastal town where your guide will take you on a sightseeing tour. You’ll walk through the old town area, which is just steps away from the Mediterranean. From this former fishing village you’ll have beautiful views of both the sea and the nearby mountains. See Port Aiguadolç, and then then walk down the famous Paseo Maritimo of Sitges, to the famous church overlooking the Mediterranean Sea. Finish off your visit with a stop to enjoy paella or fideua at a typical Catalan restaurant (not included in the tour price). If you like, you can also explore some of the many unique shops and boutiques that Sitges has to offer. '
                },
                duration: '5 hours',
                includes: 'English speaking driver and its vehicle available for the duration of the tour, tools, passenger liability insurance that is required by law and fuel. ',
                excludes: 'Entrance fees to sites, museums and meals.',
                price: '450€ 1-3 persons | 550€ 4-6 persons | 650€ 7-8 persons',
                startingAt: '450€',
                image: Sitges_Private5HoursTourfromBarcelona,
                slides: private_5_hour_tour
              },
            ],
            faq: [
                {
                  question: 'What is included in the price?',
                  answer: <ul>
                  <li>Pickup and dropoff</li>
                  <li>Free wifi onboard</li>
                  <li>All taxes, fees and handling charges</li>
                  <li>Water and Snacks</li>
                  <li>Well-maintained car with an English-speaking driver</li>
                  </ul>,
                  key:0
                },
                {
                  question: 'What is not included in the price?',
                  answer: <ul>
                    <li>Food and drinks, not specified in tour itinerary</li>
                    <li>Entrance fees to attractions</li>
                    <li>Official tour guide (you can include one after the booking)</li>
                    <li>Gratuities</li>
                  </ul>
                },
                {
                  question: 'Pick up from Barcelona Airport',
                  answer: <div>
                    Otherwise known as Josep Tarradellas Barcelona-El Prat (BCN) <br/>
                      1) Pick up your luggage and leave the baggage claim area;<br/>
                      2) The Driver will have checked your flight number so he will be waiting for you at the arrivals area;<br/>
                      3) By the Arrivals, you will find a lot of drivers. Look for the one holding the signboard with the name you indicated in the booking form;<br/>
                      4) Let the driver help you with your luggage;<br/>
                      5) Enjoy your trip! Remember that Cabbo Drivers know all the secrets of the city.<br/>
                  </div>
                },
                {
                  question: 'Pick up from another address',
                  answer: <div>1) The driver will meet you outside of the specified location, you will receive an SMS notification if you don’t meet by the indicated time.<br/>
                  2) Let the driver help you with your bags;<br/>
                  3) Enjoy your ride. Don’t forget to ask your Cabbo Driver for delicious restaurant tips or touris
                  t hotspots, they know them all!
                  </div>
                },
                {
                  question: 'What happens after I book?',
                  answer: 'Once you complete your booking you will receive a confirmation email. Feel free to reach out to our customer service team at hello@getcabbo.com for any specific questions regarding your booking. '
                },
                {
                  question: 'What is the cancellation policy?          ',
                  answer: 'You can cancel free of charge up to 24 hours prior to the booking date. No-shows or cancelation requests the day of your ride are not eligible for a refund. '
                },
                {
                  question: 'What should I wear for tours?',
                  answer: 'We recommend that you wear comfortable clothing and shoes, and to be equipped with wet weather gear if rain is expected. Please note that religious sites require modest attire - knees and shoulders must be covered. '
                },
                {
                  question: 'Local Currency',
                  answer: 'We recommend you always keep local currency on you (Euro) as unlike Cabbo, many local merchants do not accept cards as payment '
                },
                {
                  question: 'Accessibility and Special Needs',
                  answer: ' Should anyone in your group have special needs or have advanced accessibility requirements, please specify upon booking and we will make sure to accommodate your needs and customize your ride accordingly.'
                },
                {
                  question: 'Terms of service',
                  answer: <ul>
                    <li>Please note that the CABBO Drivers are not licensed to be your official tour guide at the local attractions and therefore will not accompany you into the sites. You may, however, hire a professional tour guide at an extra cost
                    </li>
                    <li>Your CABBO Driver will provide you with their local knowledge and personal recommendations between stops. Since your safety is Cabbo’s top priority, please refrain from distracting the driver during demanding driving conditions
                    </li>
                    <li>Your CABBO Driver will drop you off and pick you up as close as possible to your stop whilst always respecting local traffic laws
                      </li>
                      <li>Please consult https://getcabbo.com/toc for the full list of Terms and Conditions
                    </li>
                  </ul>,
                  key: '0'
                },
                {
                  question: 'Any other questions? ',
                  answer: 'Please write us an email, we will take just a few hours to get back to you. hello@getcabbo.com           ',
                  key: '0'
                },
            ]
        }
    }
    render() {
        return (
            <div>
                 <Tour
                    title={this.state.title}
                    subtitle={this.state.subtitle}
                    image={this.state.headerImage}
                    faq={this.state.faq}
                    reviews={this.state.reviews}
                    tours={this.state.tours}
                />
            </div>
        );
    }
}

export default BarcelonaTours;

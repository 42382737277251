import React, { Component } from 'react'
import { NavLink } from 'react-router-dom'
import moment from 'moment';

import CountUp from 'react-countup'
import TextLoop from 'react-text-loop'
import VisibilitySensor from 'react-visibility-sensor';

import Cities from '../../components/Cities/Cities'
import UniqueSalesPoints from '../../components/UniqueSalesPoints/UniqueSalesPoints'
import ReviewsSlider from '../../components/Reviews/ReviewsSlider'

import { Col, Container, Row } from 'react-bootstrap'

import './Home.scss'

import LaptopMockup from '../../assets/images/LaptopMockup.png'
import cabboDash from '../../assets/images/cabbo-dash2.png'
import heroVid from '../../assets/images/video_sito_optimized.mp4'



export default class Home extends Component {
  render() {
    return (
      <section id="homepage">
        <Container fluid className='header-new position-relative'>
          <h1 className="z-index-4">Welcome to Cabbo</h1>
        <p className='lead z-index-4'>We provide <TextLoop interval={1000} children={['an affordable', 'a reliable', 'a professional', 'a luxurious']} className='font-weight-bold text-primary' /> transportation service!</p>
      <div className="z-index-4">
        <NavLink exact className='btn btn-white' activeClassName='' to='/to'>Get Started</NavLink>
    </div>
    <div dangerouslySetInnerHTML={{ __html: `
        <video loop muted autoPlay playsinline webkit-playsinline class="hero-background-video">
          <source src="${heroVid}" type="video/mp4" />
        </video>
        ` }}
      />
    </Container>
    <UniqueSalesPoints />
  <Cities />
<Counter />
<ReviewsSlider />

<Container className='my-5 laptop-container'>
  <Row className="align-items-center">
    <Col className="mb-5 mb-md-0">
      <div className="position-relative">
        <img src={LaptopMockup} alt='Laptop Mockup' className="position-relative w-100" />
      <img src={cabboDash} alt='Map' className="position-absolute mockup-img"/>
  </div>
</Col>
<Col xs={12} md={6}>
  <h2>Do you own an hospitality facility?</h2>
<p className='lead'>Manage an B&B, hotel or corporate office? Join the booming Cabbo community and provide the best quality transportation service that adapts to all your needs.</p>
<NavLink exact className='btn btn-primary' activeClassName='' to='/partners'>Learn More!</NavLink>
</Col>
</Row>
</Container>
</section>
)
}
}

class Counter extends Component {
  constructor() {
    super();
    this.state = {
      didViewCountUp: false,
      countEnded: false
    }
  }

  onVisibilityChange = isVisible => {
    if (isVisible) {
      this.setState({didViewCountUp: true});
    }
  }

  render() {
    return (
      <Container fluid className="counter mt-5 py-5">
        <Container>
          <Row>
            <Col className="count mb-3 mb-md-0">
              <VisibilitySensor onChange={this.onVisibilityChange} offset = {{ top:
                  10}} delayedCall>
                  <span>
                    <CountUp start={0} end={this.state.didViewCountUp ? 99 : 0} duration={3}/>
                  %
                </span>
              </VisibilitySensor>
              <h5>Successful Rides</h5>
          </Col>

          <Col className="count mb-3 mb-md-0">
            <VisibilitySensor onChange={this.onVisibilityChange} offset = {{ top:
                10}} delayedCall>
                <span>
                  <CountUp start={moment()%100000} end={this.state.didViewCountUp ? 10000 : 0} duration={10000} separator=',' />
                km
              </span>
            </VisibilitySensor>
            <h5>Traveled This Month</h5>
        </Col>

        <Col className="count mb-3 mb-md-0">
          <VisibilitySensor onChange={this.onVisibilityChange} offset = {{ top:
              10}} delayedCall>
              <span>
                <CountUp start={0} end={this.state.didViewCountUp ? 18 : 0} duration={3}/>
            </span>
          </VisibilitySensor>
          <h5>Smartphones Returned</h5>
      </Col>
    </Row>
  </Container>
</Container>
)
}
}

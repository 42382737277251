import React, { Component } from 'react'

import { Container } from 'react-bootstrap'

import PageHeader from '../../components/PageHeader/PageHeader'
import Image from '../../assets/images/NewHeader.jpg'

import ContactForm from '../../components/ContactForm/ContactForm'

import './ContactUs.scss'

export default class ContactUs extends Component {
  render() {
    return (
      <div id='contact'>
      <PageHeader title='Contact Us' subtitle='Get in touch with us.' image={Image} />
        <Container className='my-5'>
          <ContactForm />
        </Container>
      </div>
    )
  }
}

import React, { Component } from 'react'
import { NavLink } from 'react-router-dom'

import Barcelona from '../../assets/images/Barcelona.png'
import Florence from '../../assets/images/Florence.png'
import Milan from '../../assets/images/Milan.png'
import Rome from '../../assets/images/Rome.png'

import { Container, Row } from 'react-bootstrap'

import './Cities.scss'

export default class Cities extends Component {
  constructor(props) {
    super(props)
    this.state = {
      cities: [
        { name: 'Barcelona', image: Barcelona, link: '/barcelona', tourLink: '/barcelona/tours', delay: '0' },
        { name: 'Florence', image: Florence, link: '/florence', tourLink: '/florence/tours', delay: '200' },
        { name: 'Milan', image: Milan, link: '/milan', tourLink: '/milan/tours', delay: '400' },
        { name: 'Rome', image: Rome, link: '/rome', tourLink: '/rome/tours', delay: '600' },
      ]
    }
  }

  render() {
    const {title, page} = this.props;
    const isTourPage = page === "tours"
    return (
      <Container className='cities my-5'>
        <h2 className='title'>{ title || 'Where would you like to be picked up?' }</h2>
        <Row>
          {this.state.cities.map((item, key) =>
            <NavLink to={ '/to' + (isTourPage ? item.tourLink : item.link)} className='col-md-3 mt-4' id='city' data-aos='fade-up' data-aos-delay={item.delay}>
              <div className='content'>{item.name}</div>
              <img src={item.image} alt={item.name} />
            </NavLink>
          )}
        </Row>
        {/* <NavLink to='/to' className='btn btn-primary mt-5'>View All</NavLink> */}
      </Container>
    )
  }
}

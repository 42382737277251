import React, { Component } from 'react'

import { Card, Container, Col, Form, Button } from 'react-bootstrap'

import './BookingForm.scss'

import ReactDatetime from "react-datetime";

// import Autocomplete from 'react-google-autocomplete';
import { getDistance } from 'geolib';
import StripeCheckout from 'react-stripe-checkout'

import TimePicker from 'react-time-picker'
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";

import moment from "moment";


// Algolia Imports 
import algoliasearch from 'algoliasearch/lite';
import { shuffle } from '@algolia/client-common';
import { InstantSearch, Index, Configure } from 'react-instantsearch-dom';
import AutoComplete from './Autocomplete';

import axios from "axios";


// import { registerLocale, setDefaultLocale } from "react-datepicker";
// import es from 'date-fns/locale/es';
// registerLocale('es', es)


// Algolia Integration 

const places = (appId = '', apiKey = '', options) => {
  const placesClient = algoliasearch(appId, apiKey, {
    hosts: [{ url: 'places-dsn.algolia.net' }].concat(
      shuffle([
        { url: 'places-1.algolia.net' },
        { url: 'places-2.algolia.net' },
        { url: 'places-3.algolia.net' },
      ])
    ),
    ...options,
  });

  // see https://community.algolia.com/places/api-clients.html#search-parameters
  const validPlacesParams = [
    // 'query', // already sent via the main parameter
    'type',
    'restrictSearchableAttributes',
    'language',
    'hitsPerPage',
    'countries',
    'aroundLatLng',
    'aroundLatLngViaIP',
    'aroundRadius',
    'getRankingInfo',
    'highlightPreTag',
    'highlightPostTag',
  ];
  return function placesSearch(query, requestOptions = {}) {
    // equivalent of "filter" on the keys
    const validRequestOptions = Object.keys(requestOptions).reduce(
      (acc, key) => {
        if (validPlacesParams.indexOf(key) > -1) {
          acc[key] = requestOptions[key];
        }
        return acc;
      },
      {}
    );
    return placesClient.transporter.read(
      {
        method: 'POST',
        path: '1/places/query',
        data: { query },
        cacheable: true,
      },
      validRequestOptions
    );
  };
};

const placesSearch = places();
const searchClient = algoliasearch(
  'U8ZK12WJ0M',
  'a3016dab7d7da03c520d9c6c36794adb'
);
const originalSearch = searchClient.search;
searchClient.search = function search(requests) {
  // categorize between a places request and another
  const requestMap = requests.reduce(
    (acc, req, requestIndex) => {
      if (req.indexName === 'places') {
        acc.places.push({ request: req, requestIndex });
      } else {
        acc.other.push(req);
      }
      return acc;
    },
    { places: [], other: [] }
  );

  return Promise.all(
    [originalSearch(requestMap.other)].concat(
      // do all places requests one by one
      requestMap.places.map(({ request, requestIndex }) =>
        placesSearch(request.params.query, request.params).then(result => ({
          result,
          // keep the desired index available
          resultIndex: requestIndex,
        }))
      )
    )
  ).then(([mainResult, ...placesResults]) => {
    // add back the places results to the main results, as i f only one query was done
    placesResults.forEach(({ result, resultIndex }) => {
      mainResult.results.splice(resultIndex, 0, result);
    });
    return mainResult;
  });
};

// End of Algolia Code 



export default class extends Component {

  constructor(props) {
    super(props)

    console.log(this.props.city)
    let zoneIdComputed = 4;

    let city = this.props.city
    if (city === 'Barcelona') {
      zoneIdComputed = 1
    } else if (city === 'Florence') {
      zoneIdComputed = 2
    } else if (city === 'Milan') {
      zoneIdComputed = 3
    } else if (city === 'Rome') {
      zoneIdComputed = 4
    }

    this.state = {
      from: '',
      to: '',
      date: new Date(),
      formattedDate: moment(new Date()).format("YYYY-MM-DD"),
      time: 'T20:00:00.000Z',
      passengersCount: 1,
      zoneId: zoneIdComputed,
      luggagesCount: 1,
      toLat: 0.0,
      toLong: 0.0,
      fromLat: 0.0,
      fromLong: 0.0,
      fromId: -1,
      toId: -1,
      locationKind: '',
      futureDateSelected: true,
      error: '',
      algoliaFilter: `zoneId=${zoneIdComputed} AND hostId=1`,
      aroundLatLong: "41.8933592, 12.4828018",
      radius: '90000',
      city: this.props.city
    }
    this.handleChange = this.handleChange.bind(this)
    this.handleDateChange = this.handleDateChange.bind(this)
    this.handleTimeChange = this.handleTimeChange.bind(this)
    this.continueBooking = this.continueBooking.bind(this)
    this.fetchCoordinatesAndRadius = this.fetchCoordinatesAndRadius.bind(this)

    this.fetchCoordinatesAndRadius();

  }

  fetchCoordinatesAndRadius = async () => {
    // e.preventDefault(); 

    await axios.get(`https://us-central1-getcabbo-1549539564765.cloudfunctions.net/webApi/v1/cms/?&cmsTag=${this.state.city.toLowerCase()}&cmsType=city`)
      .then((response) => {

        console.log("Property Data " + JSON.stringify(response.data))

        if (response.data.message === 'Not Found') {
          console.log("Error in api response, not found data")
          return true;
        } else {



          this.setState({
            aroundLatLong: response.data.cityObj.geoDefault.lat + "," + response.data.cityObj.geoDefault.lon,
            radius: response.data.cityObj.geoDefault.radius + ""
          })

        }

        return true;

      }).catch((e) => {
        console.error(e);
        // console.log("Property Data Error ")
      });

  }

  continueBooking = (e) => {
    e.preventDefault();

    this.setState({
      error: ''
    })

    console.log(this.state)

    if (this.state.from === '' || this.state.to === '') {
      this.setState({
        error: 'Please select pickup and dropoff locations'
      })
    } else if (this.state.toLat === 0.0 || this.state.fromLat === 0.0) {
      this.setState({
        error: 'Please choose the pickup and drop off locations again'
      })
    } else if (this.state.futureDateSelected === false) {
      this.setState({
        error: 'Please select a date in the future.'
      })
    } else {
      // Redirect to booking website with these values. 

      // http://book.getcabbo.com/ 
      window.location.assign(`http://book.getcabbo.com/city/${this.props.city.toLowerCase()}?to=${this.state.to}&from=${this.state.from}&date=${this.state.formattedDate}&time=${this.state.time}&passengerCount=${this.state.passengersCount}&luggageCount=${this.state.luggagesCount}&toLat=${this.state.toLat}&toLong=${this.state.toLong}&fromLat=${this.state.fromLat}&fromLong=${this.state.fromLong}&toId=${this.state.toId}&fromId=${this.state.fromId}&locationKind=${this.state.locationKind}`);
      // window.location.assign("https://book.getcabbo.com/rome/?to=Rome%20Ciampino%20Giovan%20Battista%20Pastine%20Airport%20(CIA)&from=Roma%20Termini%20Train%20Station%20(TER)&date=2020-08-27&time=T20:00:00.000Z&passengerCount=1&luggageCount=1&toLat=41.7991398&toLong=12.5928608&fromLat=41.9011172&fromLong=12.5011636&toId=0&fromId=0&locationKind=Train");

    }


  }



  handleDateChange = (date) => {

    // 2020-07-16T08:20:00.000Z

    // console.log("Date Object : " + date)

    // console.log(moment(date).format("YYYY-MM-DD"));

    // console.log("Today : " + moment(new Date()).format("YYYY-MM-DD"))

    // let today = moment(new Date());
    // console.log("Difference " + today - moment(date));

    var selectedDate = moment(date).format("YYYY-MM-DD");
    var today = moment(new Date()).format("YYYY-MM-DD");

    console.log(moment(selectedDate).isSameOrAfter(moment(today), 'day'));
    // console.log(moment(date).format("YYYY-MM-DD").isSameOrAfter(moment(new Date()).format("YYYY-MM-DD"), 'day')));


    this.setState({
      date: date,
      formattedDate: moment(date).format("YYYY-MM-DD"),
      futureDateSelected: moment(selectedDate).isSameOrAfter(moment(today), 'day')
    })

  };

  handleTimeChange = (e) => {

    // 2020-07-16T08:20:00.000Z
    console.log("T" + e.target.value + ":00.000Z");

    this.setState({
      time: "T" + e.target.value + ":00.000Z"
    })

  };

  // this function adds on the day tag of the date picker
  // middle-date className which means that this day will have no border radius
  // start-date className which means that this day will only have left border radius
  // end-date className which means that this day will only have right border radius
  // this way, the selected dates will look nice and will only be rounded at the ends
  getClassNameReactDatetimeDays = date => {
    if (this.state.startDate && this.state.endDate) {
    }
    if (
      this.state.startDate &&
      this.state.endDate &&
      this.state.startDate._d + "" !== this.state.endDate._d + ""
    ) {
      if (
        new Date(this.state.endDate._d + "") > new Date(date._d + "") &&
        new Date(this.state.startDate._d + "") < new Date(date._d + "")
      ) {
        return " middle-date";
      }
      if (this.state.endDate._d + "" === date._d + "") {
        return " end-date";
      }
      if (this.state.startDate._d + "" === date._d + "") {
        return " start-date";
      }
    }
    return "";
  };

  handleChange = (param, e) => {
    this.setState({ [param]: e.target.value });
  }

  handleTime = time => this.setState({ time });
  handleDate = date => this.setState({ date });


  render() {
    return (
      <Card id='bookingform' style={{ zIndex: 1 }}>
        <Card.Body>
          <Card.Title>Booking Form</Card.Title>
          <Form as={Container} className='px-0'>

            <Form.Row>
              <Form.Group as={Col} controlId="formPickup">
                <Form.Label>Pick Up</Form.Label>
                <InstantSearch indexName="Addresses" searchClient={searchClient}
                  style={{ width: '100%', border: '1px solid #ced4da', borderRadius: '.25rem', padding: '.375rem .75rem' }}
                >
                  <Configure aroundLatLng={this.state.aroundLatLong} aroundRadius={this.state.radius} />
                  <Configure hitsPerPage={3} filters={this.state.algoliaFilter} />
                  <AutoComplete
                    onSuggestionSelected={(event, { suggestion, suggestionValue }) => {
                      // console.log('Suggestion:', suggestion);
                      // console.log('Suggestion value:', suggestionValue);
                      this.setState({
                        from: suggestionValue,
                        fromId: suggestion.id !== null || suggestion.id !== "undefined" ? 0 : suggestion.id,
                        fromLat: suggestion._geoloc.lat,
                        fromLong: suggestion._geoloc.lng,
                        locationKind: suggestion.kind,
                      })
                    }}
                    placeholder="From"
                  />

                  <Index indexName="places" />
                </InstantSearch>
              </Form.Group>

            </Form.Row>


            <Form.Row>
              <Form.Group as={Col} controlId="formPickup">
                <Form.Label>Drop Off</Form.Label>
                <InstantSearch indexName="Addresses" searchClient={searchClient}
                  style={{ width: '100%', border: '1px solid #ced4da', borderRadius: '.25rem', padding: '.375rem .75rem' }}
                >
                  <Configure aroundLatLng={this.state.aroundLatLong} aroundRadius={this.state.radius} />
                  <Configure hitsPerPage={3} filters={this.state.algoliaFilter} />

                  <AutoComplete
                    onSuggestionSelected={(event, { suggestion, suggestionValue }) => {
                      // console.log('Suggestion:', suggestion);
                      // console.log('Suggestion value:', suggestionValue);
                      this.setState({
                        to: suggestionValue,
                        toId: suggestion.id !== null || suggestion.id !== "undefined" ? 0 : suggestion.id,
                        toLat: suggestion._geoloc.lat,
                        toLong: suggestion._geoloc.lng,
                      })
                    }}
                    placeholder="To"
                  />

                  <Index indexName="places" />
                </InstantSearch>
              </Form.Group>

            </Form.Row>

            <Form.Row>
              <Form.Group as={Col} controlId="formPassengers">
                <Form.Label>Pick Date</Form.Label>

                {/* <Form.Control
                  type="date"
                  onChange={e =>
                    this.handleDateChange(e)
                  }
                /> */}

                <DatePicker
                  selected={this.state.date}
                  value={this.state.date}
                  dateFormat="dd/MM/yyyy"
                  onChange={e =>
                    this.handleDateChange(e)
                  }
                />


              </Form.Group>
              <Form.Group as={Col} controlId="formChildren">
                <Form.Label>Pick Time</Form.Label>
                <Form.Control
                  defaultValue="20:00"
                  type="time"
                  onChange={e =>
                    this.handleTimeChange(e)
                  }
                />
              </Form.Group>
            </Form.Row>

            <Form.Row>

              <Form.Group as={Col} controlId="formLuggagelight">
                <Form.Label>Passengers</Form.Label>
                <Form.Control type="number" min="1" max="100" defaultValue={this.state.passengersCount} onChange={this.handleChange.bind(this, 'passengersCount')} />
              </Form.Group>
              <Form.Group as={Col} controlId="formLuggageheavy">
                <Form.Label>Luggage Pieces</Form.Label>
                <Form.Control type="number" min="0" max="100" defaultValue={this.state.luggagesCount} onChange={this.handleChange.bind(this, 'luggagesCount')} />
              </Form.Group>
            </Form.Row>


            <Button className="my-2" type="button"
              style={{ width: '100%', border: '1px solid #ced4da', borderRadius: '.25rem', padding: '.65rem .75rem' }}
              onClick={(e) => this.continueBooking(e)}
            >
              Continue
            </Button>

            {this.state.error !== '' ?
              <span className="error">{this.state.error}</span>
              :
              <></>}




            <Form.Row>


            </Form.Row>
          </Form>
        </Card.Body>
      </Card>
    )
  }
}

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Highlight, connectAutoComplete } from 'react-instantsearch-dom';
import AutoSuggest from 'react-autosuggest';
// import LocationCityIcon from '@material-ui/icons/LocationCity';
// import BusinessIcon from '@material-ui/icons/Business';

import { getDistance } from 'geolib';

// For Algolia. 
class AutoComplete extends Component {
    static propTypes = {
        hits: PropTypes.arrayOf(PropTypes.object).isRequired,
        currentRefinement: PropTypes.string.isRequired,
        refine: PropTypes.func.isRequired,
        onSuggestionSelected: PropTypes.func.isRequired,
    };

    state = {
        value: this.props.currentRefinement,
    };

    onChange = (event, { newValue }) => {
        this.setState({
            value: typeof newValue !== 'undefined' ? newValue : '',
        });
    };

    onSuggestionsFetchRequested = ({ value }) => {
        this.props.refine(value);
    };

    onSuggestionsClearRequested = () => {
        this.props.refine();
    };

    getSuggestionValue(hit) {
        // console.log("Suggestion Value : " + JSON.stringify(hit));
        if (hit.name) {
            return hit.name;
        } else {
            return hit.locale_names.default[0]
        }
    }



    renderSuggestion(hit) {
        // console.log(hit);
        if (hit.locale_names) {

            if (hit.name !== null && hit.locale_names === hit.name) {

            } else {

                // if (getDistance({ latitude: parseFloat(process.env.REACT_APP_LOC_1_LAT), longitude: parseFloat(process.env.REACT_APP_LOC_1_LONG) }, { latitude: hit._geoloc.lat, longitude: hit._geoloc.lng }) < 1000
                //     || getDistance({ latitude: parseFloat(process.env.REACT_APP_LOC_2_LAT), longitude: parseFloat(process.env.REACT_APP_LOC_2_LONG) }, { latitude: hit._geoloc.lat, longitude: hit._geoloc.lng }) < 500
                //     || getDistance({ latitude: parseFloat(process.env.REACT_APP_LOC_3_LAT), longitude: parseFloat(process.env.REACT_APP_LOC_3_LONG) }, { latitude: hit._geoloc.lat, longitude: hit._geoloc.lng }) < 250
                //     || getDistance({ latitude: parseFloat(process.env.REACT_APP_LOC_4_LAT), longitude: parseFloat(process.env.REACT_APP_LOC_4_LONG) }, { latitude: hit._geoloc.lat, longitude: hit._geoloc.lng }) < 100
                //     || getDistance({ latitude: parseFloat(process.env.REACT_APP_LOC_5_LAT), longitude: parseFloat(process.env.REACT_APP_LOC_5_LONG) }, { latitude: hit._geoloc.lat, longitude: hit._geoloc.lng }) < 750
                //     || getDistance({ latitude: parseFloat(process.env.REACT_APP_LOC_6_LAT), longitude: parseFloat(process.env.REACT_APP_LOC_6_LONG) }, { latitude: hit._geoloc.lat, longitude: hit._geoloc.lng }) < 100) {
                //     return null
                // } else {

                return (
                    <div style={{ display: "flex" }}>
                        <i className="fas fa-city" style={{ marginRight: '10px', marginTop: "5px" }} />
                        <Highlight attribute="locale_names.default" hit={hit} />
                    </div>
                );
                // }

            }


        } else {
            return (<div style={{ display: "flex", alignItems: "center", borderBottom: "1px solid #ccc" }}>
                <img src={hit.image} style={{ marginRight: "15px", width: "60px", height: "50px", borderRadius: "4px", marginBottom: '15px' }} />
                <div>
                    {/* <Highlight attribute="name" hit={hit} /> */}
                    <p style={{ fontSize: '12px', color: '#2b2b2b', fontWeight: "600", marginTop: '-4px' }}>{hit.name} <br /><span style={{ fontSize: '10px', color: '#696969' }}>
                        {hit.fullAddress}
                    </span></p>
                    <p></p>
                    {/* <Highlight attribute="fullAddress" hit={hit} /> */}
                </div>

            </div>)

            // return (<div style={{ display: "flex", alignItems: "center" }}>
            //     <img src={hit.image} style={{ marginRight: "15px", width: "60px", height: "50px", borderRadius: "4px" }} />
            //     <Highlight attribute="name" hit={hit} />
            // </div>)
        }

        {/* <div style={{ display: "flex", flexDirection: "column" }}>
                <Highlight attribute="name" hit={hit} />
                <Highlight attribute="fullAddress" hit={hit} />
            </div> */}
        // return <Highlight attribute="name" hit={hit} /> // tagName="mark"
    }

    renderSectionTitle(section) {
        return section.index;
    }

    getSectionSuggestions(section) {
        return section.hits;
    }

    render() {
        const { hits, onSuggestionSelected, placeholder } = this.props;
        const { value } = this.state;

        const inputProps = {
            placeholder: placeholder,
            onChange: this.onChange,
            value,
        };

        return (
            <AutoSuggest
                suggestions={hits}
                multiSection={true}
                onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
                onSuggestionsClearRequested={this.onSuggestionsClearRequested}
                onSuggestionSelected={onSuggestionSelected}
                getSuggestionValue={this.getSuggestionValue}
                renderSuggestion={this.renderSuggestion}
                shouldRenderSuggestions={() => true}
                inputProps={inputProps}
                renderSectionTitle={this.renderSectionTitle}
                getSectionSuggestions={this.getSectionSuggestions}
            />
        );
    }
}

export default connectAutoComplete(AutoComplete);

import React, { Component } from 'react'
import { NavLink } from 'react-router-dom'

import { Col, Container, Navbar, Nav, Row } from 'react-bootstrap'

import './Navigation.scss'

import Logo from '../../assets/images/Logo.png'

export default class Navigation extends Component {

  // constructor(props) {
  //   super(props);
  //   this.state = {
  //     isNavExpanded: false
  //   };

  //   this.setIsNavExpanded = (isNavExpanded) => {
  //     this.setState({ isNavExpanded: isNavExpanded });
  //   }

  //   this.handleClick = (e) => {
  //     if (this.node.contains(e.target)) {
  //       // if clicked inside menu do something
  //     } else {
  //       // If clicked outside menu, close the navbar.
  //       this.setState({ isNavExpanded: false });
  //     }
  //   }
  // }
  componentDidMount() {
    document.addEventListener('click', this.handleClick, false);
  }

  componentWillUnmount() {
    document.removeEventListener('click', this.handleClick, false);
  }

  render() {
    var NavbarStyle = {
      alignItems: 'center',
      display: 'flex',
      justifyContent: 'center',
      textAlign: 'center'
    }
    return (
      <div style={{ paddingBottom: '54px' }}>
        <Navbar className="bg-white fixed-top" expand='lg' style={{ zIndex: 40 }} collapseOnSelect
        >
          <Container>
            <Row>
              <Col>
                <NavLink exact className='navbar-brand' to='/'><img src={Logo} alt='GetCabbo Logo' width='120' /></NavLink>
              </Col>
              <Col className='centered'>
                <Navbar.Toggle className='navbar-toggle' aria-controls='basic-navbar-nav' />
              </Col>

              <Col md={8} style={NavbarStyle}>
                <Navbar.Collapse id='basic-navbar-nav' collapseOnSelect>
                  <Nav>
                    {/* <NavLink exact  className='nav-link' activeClassName='active' to='/' >Home</NavLink> */}
                    {/* <NavLink exact  className='nav-link' activeClassName='active' to='/to'  > Transfers</NavLink>
                    <NavLink exact  className='nav-link' activeClassName='active' to='/tours' > Tours</NavLink>
                    <NavLink exact  className='nav-link' activeClassName='active' to='/partners' > Partners</NavLink>
                    <NavLink exact className='nav-link' activeClassName='active' to='/about' > About</NavLink> */}

                    <Nav.Item>
                      <Nav.Link exact eventKey="1" as={NavLink} to="/">
                        Home
                      </Nav.Link>
                    </Nav.Item>

                    <Nav.Item>
                      <Nav.Link exact eventKey="2" as={NavLink} to="/to">
                        Transfers
                      </Nav.Link>
                    </Nav.Item>

                    <Nav.Item>
                      <Nav.Link exact eventKey="3" as={NavLink} to="/tours">
                        Tours
                      </Nav.Link>
                    </Nav.Item>

                    <Nav.Item>
                      <Nav.Link exact eventKey="4" as={NavLink} to="/partners">
                        Partners
                      </Nav.Link>
                    </Nav.Item>

                    <Nav.Item>
                      <Nav.Link exact eventKey="5" as={NavLink} to="/about">
                        About
                      </Nav.Link>
                    </Nav.Item>


                  </Nav>
                </Navbar.Collapse>
              </Col>
            </Row>
          </Container>
        </Navbar>
      </div>
    )
  }
}

import React, { Component } from 'react'
import { Col, Container, Row, Carousel, Card, Button } from 'react-bootstrap'
import Slider from "react-slick";
import BookingForm from '../BookingForm/BookingForm'
import FAQ from '../FAQ/FAQ'
import ScrollUpButton from "react-scroll-up-button";
import ReviewsSlider from '../../components/Reviews/ReviewsSlider'
import { MobileTourSlider } from './Mobile'

import './Destination.scss'

import assistance from '../../assets/images/transfers/assistance.png'
import cab from '../../assets/images/meet-icon.svg'
import confirmation2 from '../../assets/images/booking-icon.svg'
import fixed from '../../assets/images/transfers/fixed.png'
import relax from '../../assets/images/transfers/relax2.png'
import ride from '../../assets/images/car-driver.svg'
import umbrella from '../../assets/images/transfers/umbrella.png'

export default class Destination extends Component {
  constructor(props) {
    super(props)

    console.log("Destination Web.js file, city name " + this.props.title);

    let zoneIdfetched = -1;

    let city = this.props.title
    if (city === 'Barcelona') {
      zoneIdfetched = 1
    } else if (city === 'Florence') {
      zoneIdfetched = 2
    } else if (city === 'Milan') {
      zoneIdfetched = 3
    } else if (city === 'Rome') {
      zoneIdfetched = 4
    }

    this.state = {
      view: 0,
      title: '',
      mobile: false,
      description: {
        brief: '',
        full: ''
      },
      duration: '',
      zoneId: zoneIdfetched,
      includes: '',
      excludes: '',
      price: '',
      image: '',
      code: 0,
      steps: [
        {
          title: 'Book Now',
          subtitle: 'at best rate available ',
          icon: confirmation2
        },
        {
          title: 'Meet & Greet',
          subtitle: 'wherever you want',
          icon: cab
        },
        {
          title: 'Ride in Style',
          subtitle: 'with our luxury cars',
          icon: ride
        },
        {
          title: 'Enjoy your Stay',
          subtitle: 'play work eat explore',
          icon: relax
        }
      ],
      benefits: [
        {
          title: 'Free Cancellation',
          icon: umbrella
        },
        {
          title: '24/7 Assistance',
          icon: assistance
        },
        {
          title: 'Guaranteed Fixed Rates',
          icon: fixed
        }
      ]
    }
  }

  componentDidMount() {
    (document.documentElement.clientWidth <= 992) ? this.setState({ mobile: true }) : this.setState({ mobile: false });
    window.addEventListener('resize', () => {
      (document.documentElement.clientWidth <= 992) ? this.setState({ mobile: true }) : this.setState({ mobile: false });
      console.log(this.state.mobile);
    })
    console.log(document.documentElement.clientWidth);
    console.log("mobile ===" + this.state.mobile);
  }

  openTour(tour) {
    this.setState(tour);
    this.setState({ view: 1 });
  }

  goToBookingWebsite(e) {
    e.preventDefault();
    window.location.assign(`http://book.getcabbo.com/city/${this.props.title.toLowerCase()}`);

  }

  render() {

    return (
      <div id='destination'>
        <Container fluid id='fullscreen-header' className="d-flex align-items-center position-relative dark-overlay">
          <img className="position-absolute card-img-absolute" src={this.props.image} alt="background" />
          <Container className="position-relative overlay-content">
            <Row className="align-items-center">
              <Col xs={12} lg={8}>
                <Row>
                  <h3 className='font-weight-normal text-white my-4' data-aos='fade-right' data-aos-delay='200'>Your Transfer Service in <span className="text-primary h3">{this.props.title}</span></h3>
                  <p className='lead mb-5 h4 text-white' data-aos='fade-right' data-aos-delay='200'>Between the city center and the main hubs</p>
                </Row>
                <Row className="steps">
                  {this.state.steps.map((step, i) => {
                    return (
                      this.state.mobile ?
                        <Col key={i} xs={6} className="p-0">
                          <div class="d-flex step align-items-start mb-4">
                            {/* <div className="bg-white p-1 mr-2 rounded-circle">
                              <img alt="icon" src={step.icon} style={{ width: '2rem' }}/>
                            </div> */}
                            <div class="d-flex flex-column">
                              <h6 className="text-white">{step.title}</h6>
                              <p className="mb-0 text-white">{step.subtitle}</p>
                            </div>
                          </div>
                        </Col>
                        :
                        <Col key={i} xs={6}>
                          <div class="d-flex step align-items-center mb-5">
                            <img alt="icon" src={step.icon} style={{ width: '5rem' }} className="mr-4" />
                            <div className="d-flex flex-column">
                              <h4 className="text-white mb-2">{step.title}</h4>
                              <p className="text-white">{step.subtitle}</p>
                            </div>
                          </div>
                        </Col>
                    )
                  })}
                </Row>
                <Row xsHidden>
                  <Col xs={12} className="d-flex flex-wrap mb-4 benefits justify-content-around">
                    {this.state.benefits.map((benefit) => {
                      return (
                        this.state.mobile ?
                          <div class="d-flex flex-column align-items-start mb-4 mr-auto" style={{ flexBasis: 'auto' }}>
                            <img alt="icon" style={{ width: '2.5rem' }} src={benefit.icon} className="mb-2" />
                            <p className="mb-0 text-white font-weight-bold text-center">{benefit.title}</p>
                          </div>
                          :
                          <div class="d-flex flex-column align-items-start mr-5">
                            <img alt="icon" style={{ width: '3rem' }} src={benefit.icon} className="mr-2 mb-2" />
                            <p class="mb-0 font-weight-bold text-white">{benefit.title}</p>
                          </div>
                      )
                    })}
                  </Col>

                </Row>
                <Row>

                  <Col xs={12} className="btn-block mb-4 d-sm-none">
                    <button type="button"
                      class="btn btn-primary btn-block"
                      onClick={(e) => this.goToBookingWebsite(e)}>
                      Book your transfer
                      </button>
                  </Col>

                </Row>
              </Col>
            </Row>
          </Container>
        </Container>

        <Container className='my-5'>
          <Row className="position-relative">
            <Col xs={12} md={7}>
              <p className='lead mb-5'>{this.props.description}</p>

              <h3>About {this.props.title}</h3>
              <p>{this.props.story}</p>
            </Col>
            <Col md={4} xsHidden className="booking-form-fixed fixed-form-container d-none d-sm-block">
              <BookingForm city={this.props.title} country={this.props.country} />
            </Col>
          </Row>


        </Container>

        <Container fluid className="gradient-yellow pt-4 pb-5">
          {
            this.state.mobile ? 
            <MobileTourSlider tours={this.props.tours} openTour={(tour) => this.openTour(tour)} />
            :
            <TourSlider tours={this.props.tours} openTour={(tour) => this.openTour(tour)} />
          }
        </Container>

        <Container className="mb-5">
          <Row>
            <Col xs={12} md={7}>
              <h3 className='mt-5'>Useful Information</h3>
              {this.props.knowledge.map((data) =>
                <Knowledge
                  title={data.title}
                  content={data.content}
                />
              )}
            </Col>
          </Row>
        </Container>

        <ReviewsSlider />

        <Container className="mt-5">
          <FAQ data={this.props.faq} />
        </Container>

        {this.state.view === 1 ?
          <>
            <div className="popup-overlay" onClick={() => this.setState({ view: 0 })}>
            </div>

            <div class="close-card" onClick={() => this.setState({ view: 0 })}>X</div>
            <MainTourCard data={this.state} city={this.props.title} />
          </>

          : <></>}

        {/* <ScrollUpButton
          ContainerClassName="ScrollUpButton__Container"
          TransitionClassName="ScrollUpButton__Toggled"
          StopPosition={0}
          ShowAtPosition={1000}
          EasingType='easeOutCubic'
          AnimationDuration={500}
        // onClick={window.location.assign('http://book.getcabbo.com')}
        >
          <div>Book your Transfer</div>
        </ScrollUpButton> */}
      </div>
    )
  }
}

class Knowledge extends Component {
  render() {
    return (
      <div className='knowledge'>

        <p className='lead mb-1'>{this.props.title}</p>
        <p className='mb-1 small text-primary'>{this.props.price}</p>
        <div className="knowledge-body">
          <p>{this.props.content}</p>
          {this.props.image ?
            <img src={this.props.image} alt={this.props.title} className="image" />
            : <></>}
        </div>
      </div>
    )
  }
}

class MainTourCard extends Component {

  render() {
    let Slides = [""];
    let code = this.props.data.code;
    if (code === 11) {
      Slides = ["/images/Barcelona/1/2.png", "/images/Barcelona/1/3.png", "/images/Barcelona/1/4.png", "/images/Barcelona/1/5.png"]
    }
    else if (code === 12) {
      Slides = ["/images/Barcelona/2/2.png", "/images/Barcelona/2/3.png", "/images/Barcelona/2/4.png", "/images/Barcelona/2/5.png"]
    }
    else if (code === 13) {
      Slides = ["/images/Barcelona/3/2.png", "/images/Barcelona/3/3.png", "/images/Barcelona/3/4.png", "/images/Barcelona/3/5.png", "/images/Barcelona/3/6.png"]
    }
    else if (code === 21) {
      Slides = ["/images/Florence/1/2.png", "/images/Florence/1/3.png", "/images/Florence/1/4.png", "/images/Florence/1/5.png", "/images/Florence/1/6.png"]
    }
    else if (code === 22) {
      Slides = ["/images/Florence/2/2.png", "/images/Florence/2/3.png", "/images/Florence/2/4.png", "/images/Florence/2/5.png", "/images/Florence/2/6.png"]
    }
    else if (code === 31) {
      Slides = ["/images/Milan/1/2.png", "/images/Milan/1/3.png", "/images/Milan/1/4.png", "/images/Milan/1/5.png", "/images/Milan/1/6.png",]
    }
    else if (code === 32) {
      Slides = ["/images/Milan/2/2.png", "/images/Milan/2/3.png", "/images/Milan/2/4.png",]
    }
    else if (code === 33) {
      Slides = ["/images/Milan/3/2.png", "/images/Milan/3/3.png", "/images/Milan/3/4.png", "/images/Milan/3/5.png",]
    }
    else if (code === 34) {
      Slides = ["/images/Milan/4/2.png", "/images/Milan/4/3.png", "/images/Milan/4/4.png", "/images/Milan/4/5.png",]
    }
    else if (code === 41) {
      Slides = ["/images/Rome/1/2.png", "/images/Rome/1/3.png", "/images/Rome/1/4.png", "/images/Rome/1/5.png", "/images/Rome/1/6.png", "/images/Rome/1/7.png",]
    }
    else if (code === 42) {
      Slides = ["/images/Rome/2/2.png", "/images/Rome/2/3.png", "/images/Rome/2/4.png", "/images/Rome/2/5.png",]
    }
    else if (code === 43) {
      Slides = ["/images/Rome/3/2.png", "/images/Rome/3/3.png", "/images/Rome/3/4.png", "/images/Rome/3/5.png", "/images/Rome/3/6.png", "/images/Rome/3/7.png", "/images/Rome/3/8.png",]
    }
    else if (code === 44) {
      Slides = ["/images/Rome/4/6.png", "/images/Rome/4/2.png", "/images/Rome/4/3.png", "/images/Rome/4/4.png", "/images/Rome/4/5.png",]
    }
    else if (code === 45) {
      Slides = ["/images/Rome/5/2.png", "/images/Rome/5/3.png", "/images/Rome/5/4.png", "/images/Rome/5/5.png",]
    }
    else if (code === 46) {
      Slides = ["/images/Rome/6/2.png", "/images/Rome/6/3.png", "/images/Rome/6/4.png", "/images/Rome/6/5.png", "/images/Rome/6/6.png", "/images/Rome/6/7.png", "/images/Rome/6/8.png",]
    }
    return (
      <div className="tour-card show-above">


        <div className="tour-card-img-top">
          <Carousel>
            {Slides.map((data) =>
              <Carousel.Item>
                <img
                  className="d-block w-100"
                  src={data}
                  alt="slide"
                />
              </Carousel.Item>

            )}

          </Carousel>

        </div>
        <div class="tour-card-sa-body">
          <h4 className="tour-card-title mb-2 name">{this.props.data.title}</h4>
          <p className="tour-card-text text-muted">{this.props.data.description.full}</p>
          <p className="tour-card-text text-muted price"><b>Price : </b>{this.props.data.price}</p>
          <p className="tour-card-text text-muted price"><b>Duration : </b>{this.props.data.duration}</p>
          <p className="tour-card-text text-muted price"><b>The tour includes : </b>{this.props.data.includes}</p>
          <p className="tour-card-text text-muted price"><b>The tour excludes : </b>{this.props.data.excludes}</p>

          <p className="tour-card-text text-muted">Reserve now contacting us via Whatsapp at +393515909874 or emailing booking@getcabbo.com</p>

        </div>

      </div>
    )
  }
}


export class TourSlider extends Component {
  render() {
    const { tours } = this.props;
    const sliderSettings = {
      className: "slider variable-width",
      mobileFirst: true,
      centerMode: false,
      infinite: true,
      variableWidth: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      autoplay: false,
      autoplaySpeed: 2000,
      cssEase: "linear",
      arrows: false,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1,
            infinite: true,
            variableWidth: false,
            centerMode: false
          }
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1,
            variableWidth: false,
            centerMode: false
          }
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            centerMode: false,
            variableWidth: false
          }
        }
      ]
    };

    return (
      <Container fluid className="tours-slider">
        <Row className="py-2 px-4 justify-content-between">
          <div>
            <h4 className="text-white">Our Tours</h4>
          </div>
          <div class="controls position-relative">
            <button className="slick-arrow slick-prev" onClick={() => this.slider.slickPrev()}>
            </button>
            <button className="slick-arrow slick-next" onClick={() => this.slider.slickNext()}>
            </button>
          </div>
        </Row>
        <Row>
          <Col xs={12}>
            <Slider ref={slider => (this.slider = slider)} {...sliderSettings}>
              {tours.map((tour, i) => {
                return (
                  <Card key={i} className="mb-5 tour-card-item" onClick={() => this.props.openTour(tour)}>
                    <Row className="ml-0">
                      <Col xs={4} className="position-relative">
                        <img src={tour.image} alt="card img" className="position-absolute card-img-absolute" />
                      </Col>
                      <Col xs={8}>
                        <Card.Body className="d-flex flex-column">
                          <Card.Title>{tour.title}</Card.Title>
                          <Card.Text className="mb-4 truncate-lines-2" style={{ maxHeight: `${24 * 4}px` }}>
                            {tour.description.brief}
                          </Card.Text>
                          <div className="d-flex mt-auto">
                            <Card.Text>Starting From {tour.startingAt}</Card.Text>
                            <Card.Link href="#" className="ml-auto book-now">BOOK NOW!</Card.Link>
                          </div>
                        </Card.Body>
                      </Col>
                    </Row>
                  </Card>
                )
              })}
            </Slider>
          </Col>
        </Row>
      </Container>
    )
  }
}
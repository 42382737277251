import React from 'react'

export default function Star({numStars = 0}) {
    var rows = [], i = 0, len = numStars;
    while (++i <= len) rows.push(i);

    return (
        <div>
            {rows.map(function (i) {
                return (
                    <svg key={i} height="25" width="23" className="star-rating">
                        <polygon points="9.9, 1.1, 3.3, 21.78, 19.8, 8.58, 0, 8.58, 16.5, 21.78" style={{fillRule: 'nonzero', fill: '#00b67a'}}/>
                    </svg>
                )
             })}
        </div>
    )
}

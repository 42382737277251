import React, { Component } from 'react';
import { Col, Container, Row} from 'react-bootstrap';
import BookingForm from '../BookingForm/BookingForm';
import './PageHeader.scss'
// import TourForm from '../Tour/TourForm'

export default class FullscreenHeader extends Component {
    render() {
        const {title, subtitle, countryName, countryCode, backgroundImage, buttonText, buttonClickAction} = this.props
        const showBookingForm = false;
        return (
            <Container fluid id='fullscreen-header' className="d-flex align-items-center position-relative dark-overlay">
                <img className="position-absolute card-img-absolute" src={backgroundImage} alt="background"/>
                <Container className="overlay-content">
                    <Row>
                        <Col xs={12} lg={8}>
                            <p className='text-white font-weight-normal mb-4 title' data-aos='fade-right' data-aos-delay='200'>{title}</p>
                            <h1 className='text-white' data-aos='fade-right' data-aos-delay='200'>{subtitle}</h1>
                            {buttonText && 
                                <>
                                <button className="btn btn-primary mt-5" onClick={buttonClickAction} data-aos='fade-right' data-aos-delay='200'>{buttonText}</button>
                                <p className="text-below-button" data-aos='fade-right' data-aos-delay='200'>100% free - No integration costs</p>
                                </>
                            }
                        </Col>
                        <Col lg={4} className="booking-form-fixed d-none d-lg-block">
                            { showBookingForm && <BookingForm data-aos='fade-left' data-aos-delay='200' title={countryName} country={countryCode} ref={this.stickyForm}/> }
                            {/* { tours && <TourForm tours={tours}/> } */}
                        </Col>
                    </Row>
                </Container>
            </Container>           
        );
    }
}


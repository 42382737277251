import React, { Component } from 'react'
import FullscreenHeader from '../PageHeader/FullscreenHeader'
import FAQ from '../FAQ/FAQ'
import { Col, Container, Row, Card, Carousel } from 'react-bootstrap'
import mailIcon from '../../assets/images/mail.svg'
import TourForm from './TourForm.js'
import GCContext from '../GCContext'
import ScrollUpButton from "react-scroll-up-button"
import ReviewsSlider from '../../components/Reviews/ReviewsSlider'

import {Elements} from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js';

import './Tour.scss'

export default class Tour extends Component {
    constructor(props) {
      super(props)

      this.state = {
          view: 0,
          data: 0,
          formDistancetoTop: 0,
          reviewSectionDistanceToTop: 0,
          openTour: {}
        }
        
        this.stickyForm = React.createRef();
    }

    static contextType = GCContext;
    
    openTour(data){
        this.context.webpMachine.polyfillDocument();
        this.setState({openTour: data});
        this.setState({view: 1});
    }
  
    render() {
        const {title, subtitle, image, countryName, countryCode, reviews, tours} = this.props;
      return (
        <div id="tours-page">   

            <FullscreenHeader title={title} subtitle={subtitle} backgroundImage={image} countryName={countryName} countryCode={countryCode} tours={tours}/> 

            <Container className="mt-5 d-lg-none">
                <TourForm tours={tours}/>
            </Container>
            
            <Container className="py-5 d-none d-lg-block">
                <Row className="position-relative">
                    <Col xs={12} lg={8}>
                        {tours.map( (tour, i) => {
                            return ( 
                                <Card key={i} className="mb-5 tour-card-item" onClick={() => this.openTour(tour)}>
                                    <Row>
                                        <Col xs={4} className="position-relative">
                                            <img src={tour.image} alt="card img" className="position-absolute card-img-absolute"/>
                                        </Col>
                                        <Col xs={8}>
                                            <Card.Body>
                                                <Card.Title>{tour.title}</Card.Title>
                                                <Card.Text className="mb-4 truncate-lines-2" style={{maxHeight: `${24 * 4}px`}}>
                                                  {tour.description.brief}
                                                </Card.Text>
                                                <div className="d-flex">
                                                    <Card.Text>Starting From {tour.startingAt}</Card.Text>
                                                    <Card.Link href="#" className="ml-auto book-now">BOOK NOW!</Card.Link>
                                                </div>
                                            </Card.Body>
                                        </Col>
                                    </Row>
                                </Card>
                            )
                        })}
                    </Col>
                    <Col xs={12} lg={4} className="fixed-form-container"> 
                        <TourForm tours={tours} />
                    </Col>
                </Row>    
            </Container>  

            <Container className="py-5 d-lg-none">
                <Row>
                    <Col xs={12} lg={8}>
                        {tours.map( (tour, i) => {
                            return ( 
                                <Card key={i} className="mb-5 tour-card-item" onClick={() => this.openTour(tour)}>
                                    <Card.Img style={{height: '15rem', objectFit: 'cover', objectPosition: 'center'}} top width="100%" src={tour.image} alt="Card image cap" />
                                    <Card.Body>
                                      <Card.Title>{tour.title}</Card.Title>
                                      <Card.Text className="mb-4 truncate-lines-2" style={{maxHeight: `${24 * 4}px`}}>
                                        {tour.description.brief}
                                      </Card.Text>
                                      <Card.Text className="lead">
                                          Starting From {tour.startingAt}
                                        </Card.Text>
                                      <Card.Link href="#" className="ml-auto book-now">BOOK NOW!</Card.Link>
                                    </Card.Body>
                                </Card>
                            )
                        })}
                    </Col>
                </Row>    
            </Container>  

            <ReviewsSlider/>

            <Container className="mt-5"> 
                <FAQ data={this.props.faq}/>
            </Container>

            <ScrollUpButton
            ContainerClassName="ScrollUpButton__Container"
            TransitionClassName="ScrollUpButton__Toggled"
            StopPosition={window.innerWidth <= 992 ? 632 : 0}
            ShowAtPosition={1000}
            EasingType='easeOutCubic'
            AnimationDuration={500}>
              <div>Scroll Back to Reservation Form</div>
            </ScrollUpButton>

            {this.state.view===1 ?
                <>
                <div className="popup-overlay d-flex align-items-center justify-content-center" onClick={() => this.setState({view:0})}>
                </div>

                <MainTourCard data={this.state} city={this.props.title} tour={this.state.openTour}/>

                <div className="close-card" onClick={() => this.setState({view:0})}>X</div>
                </>

                : <></>}
            </div>
      )
    }
}

class MainTourCard extends Component {

    render() {
        const {tour} = this.props;

        return (
        <div className="tour-card show-above">
            <div className="tour-card-img-top">
                <Carousel>
                    {Object.entries(tour.slides).map((slide)=>
                        <Carousel.Item>
                            <img src={slide[1]} alt="slide" style={{minHeight: '280px'}} />
                        </Carousel.Item>  
                    )}
                </Carousel>
            </div>
            <div class="tour-card-sa-body pt-3">  
                <h4 className="tour-card-title mb-2 name">
                    {tour.title}
                </h4>             
                <p className="tour-card-text text-muted">
                    {tour.description.full}
                </p>
                <p className="tour-card-text text-muted price">
                    <b>Price : </b>{tour.price}
                </p>
                <p className="tour-card-text text-muted price">
                    <b>Duration : </b>{tour.duration}
                </p>
                <p className="tour-card-text text-muted price">
                    <b>The tour includes : </b>{tour.includes}
                </p>
                <p className="tour-card-text text-muted price">
                    <b>The tour excludes : </b>{tour.excludes}
                </p>
                <p className="tour-card-text text-muted">
                    <a href="mailto:booking@getcabbo.com">
                        <img class="mr-1 mb-0 align-bottom" src={mailIcon} alt="mail" style={{width: '1.5rem'}}/>
                        Reserve {tour.title}
                    </a>
                </p>
            </div>
        </div>
        )
    }
}
import React, { Component } from 'react';
import RomeImage from '../../assets/images/Rome.png'
import Tour from '../../components/Tour/Tour'
import NaplesAndPompeiMainImage from '../../assets/images/Rome/1.webp'
import TivoliMain from '../../assets/images/Rome/2.webp'
import SorrentoMain from '../../assets/images/Rome/3.webp'
import SanSebastianoMain from '../../assets/images/Rome/4.webp'
import RomeHalfMain from '../../assets/images/Rome/5.webp'
import OstiaAnticaMain from '../../assets/images/Rome/6.webp'
import { importAll } from '../../utils/helperFunctions'

const naplesSlides = importAll(require.context('../../assets/images/Rome/tours/Naples_and_Pompei', false, /\.(png|jpe?g|svg|webp)$/));
const ositaSlides = importAll(require.context('../../assets/images/Rome/tours/Ostia_Antica', false, /\.(png|jpe?g|svg|webp)$/));
const romeHalfDaySlides = importAll(require.context('../../assets/images/Rome/tours/Rome_Half_Day', false, /\.(png|jpe?g|svg|webp)$/));
const sanSabastianSlides = importAll(require.context('../../assets/images/Rome/tours/San_Sebastiano_Catacombs_and_Appia_Antica', false, /\.(png|jpe?g|svg|webp)$/));
const sorrentoSlides = importAll(require.context('../../assets/images/Rome/tours/Sorrento_and_the_Amalfi_Coast', false, /\.(png|jpe?g|svg|webp)$/));
const tivoliSlides = importAll(require.context('../../assets/images/Rome/tours/Tivoli_Villa_d_Este_Villa_Adriana', false, /\.(png|jpe?g|svg|webp)$/));

class RomeTours extends Component {
    constructor(props) {
        super(props);
        this.state = {
            title: 'Choose the experience you like the most and make your stay in Rome unique',
            subtitle: 'The city is all yours!',
            headerImage: RomeImage,
            countryName: 'Rome',
            countryCode: 'es',
            reviews: [
                {
                  avatar: 'https://via.placeholder.com/150/FFFFFF/FFFFFF/FFFFFF',
                  name:'P.B.H.',
                  stars:'5',
                  text: 'Reliable, punctual, careful and helpful driver. Car roomy, clean and tidy. Recommended.'
                },
                {
                  avatar: 'https://via.placeholder.com/150/FFFFFF/FFFFFF/FFFFFF',
                  name:'Luigi Pedace',
                  stars:'5',
                  text: "I often use Cabbo and I've never been disappointed. Quality service and very professional drivers."
                },
                {
                  avatar: 'https://via.placeholder.com/150/FFFFFF/FFFFFF/FFFFFF',
                  name:'Katie Raymond',
                  stars:'5',
                  text: 'Ideal airport transportation! Could not have been easier. He took our bags and then a smooth ride to our destination.'
                },
              ],
            tours: [
              {
                title: 'Tivoli: Villa d\'Este & Villa Adriana',
                code:41,
                description: {
                  brief: 'This tour is designed for passengers that want to enjoy a day admiring the incomparable artistic and cultural heritage that tivoli and their villas  has to offer. ',
                  full: 'Start your trip from Rome to Tivoli to visit 2 of Italy\'s most spectacular villas, thanks to this full-day excursion to Villa d\'Este and Villa Adriana, which will give you enough time to discover these at best two splendid residences immersed in the countryside. Reach Villa Adriana, built the 2nd century by Emperor Adriano, which was once even larger than the center of Imperial Rome. It is the largest and most luxurious residence ever built in Italy and boasts an impressive collection of imperial palaces, temples, theaters, spas and swimming pools. After lunch, you will visit Villa d’Este, masterpiece of the Italian Garden, is included in the UNESCO world heritage list. With its impressive concentration of fountains, nymphs, grottoes, plays of water, and music, it constitutes a much-copied model for European gardens in the mannerist and baroque styles.'
                },
                duration: '5 hours',
                includes: 'English speaking driver and its vehicle available for the duration of the tour, fuel, tools, passenger liability insurance that is required by law and fuel.',
                excludes: 'Entrance fees to sites, museums and meals.',
                price: '210€ 1-3 persons | 270€ 4-6 persons | 310€ 7-8 persons',
                startingAt: '210€',
                image: TivoliMain,
                slides: tivoliSlides
              },
              {
                title: 'Sorrento and the Alfami Coast',
                code:42,
                description: {
                  brief: 'This tour is designed for passengers who want to enjoy the landscapes and flavors of the Amalfi coast.',
                  full: 'With this tour we have the solution for you, try this tour with our professional drivers and our service with official tour guide that will make you feel like a local person, discover local people\'s uses, taste their genuine products and touch with hand the famous hospitality of the locals. Sorrento and the Amalfi Coast are famous locations all over the world, their characteristic seafront location as well as their architectural beauty make these places a unique place in the world that blends perfectly in a territory that passes quickly from the sea to the mountains with small villages built directly on the shore over the sea. The crystalline sea and the fantastic restaurants and traditions typical of the place will make your day unforgettable and you will bring home with you a memory that you will hardly forget.'
                },
                duration: '12 hours',
                includes: 'English speaking driver and its vehicle available for the duration of the tour, fuel, tools, passenger liability insurance that is required by law and fuel.',
                excludes: 'Entrance fees to sites, museums and meals.',
                price: '890€ 1-3 persons | 970€ 4-6 persons | 1070€ 7-8 persons',
                startingAt: '890€',
                image: SorrentoMain,
                slides: sorrentoSlides
              },
              {
                title: 'San Sebastiano and the Appias\'s Monuments',
                code:43,
                description: {
                  brief: 'Looks back on 2300 years of history. At the Via Appia Antica in Rome you will find several catacombs Today, there is a spacious regional park around the old street.',
                  full: 'The Catacombs of San Sebastiano and the monuments of the Appia Antica The Via Appia Antica is a veritable treasure trove of archaeological treasures of inestimable value and great interest that illustrate the past of Rome. On this tour you will find a guided tour of the catacombs of San Sebastiano, the Circus of Maxentius and the Tomb of Cecilia Metella in the Appia Antica park; a charming and enchanting place in the countryside, away from traffic and mass tourism.'
                },
                stops: [
                  {
                    title: 'The Villa and the Cirus of Mexentius',
                    description: 'The best preserved circus in the ancient world. The first stop on the tour is the Villa of Maxentius complex on the Via Appia Antica. An archaeological site of enormous interest that contains the remains of the immense extra-urban residence desired by the emperor Maxentius (306-3121 AD). The imposing and very well preserved ruins of the Circus of Maxentius are of particular interest. A site that will allow you to fully appreciate and understand (and better than anywhere else in the empire) the fascination exerted by the spectacle of chariot races in antiquity. The imposing remains of the two tall towers placed at the ends of the starting blocks of the wagons and the triumphal gate from which the quadrigas made their entrance are still visible from the circus. But what makes this monument unique is the integrity of the perfectly legible track in the ground. It is still surrounded by the remains of the steps and inside it is possible to admire the perfectly preserved central spine, around which the carts completed the 7 laps envisaged by the races. The funerary monument intended for the imperial family is of imposing size and built in imitation of the famous Pantheon building.'
                  },
                  {
                    title: 'The Mausoleum of Cecilia Metella',
                    description: 'The tomb for a Roman aristocrat. The Mausoleum of Cecilia Metella is a true monument symbol of Rome and an integral part of the evocative landscape with ruins so loved by the artists of the grand tour. Along the way there are also paved sections that evoke the glorious past of the Regina viarum, the oldest paved road built by the Romans; a work of the modern age.'
                  },
                  {
                    title: 'The Catacombs of San Sebastiano',
                    description: 'The oldest Christian catacombs in Rome. A visit to the Catacombs of Rome is certainly one of the obligatory stops of the tourist who loves Rome\'s past and its archaeological beauties. Among the numerous catacombs that rise on the Appia Antica, we offer our clients the complex of the San Sebastiano catacombs: the first official burial place of the Christians; and one of the largest and most important underground cemeteries of the ancient city. The site is also linked to the memory of the apostles St. Peter and St. Paul who were temporarily buried here in the middle of the 3rd century. to remove them from persecution against Christians. In safer times they returned to their original burial places but their presence in the catacombs made the latter famous. Along the way there are also paved sections that evoke the glorious past of the Regina viarum, the oldest paved road built by the Romans; a work of the modern age.'
                  }
                ],
                duration: '5 hours',
                includes: 'English speaking driver and its vehicle available for the duration of the tour, fuel, tools, passenger liability insurance that is required by law and fuel.',
                excludes: 'Entrance fees to sites, museums, etc and meals.',
                price: '180€ 1-3 persons | 220€ 4-6 persons | 250€ 7-8 persons',
                startingAt: '180€',
                image: SanSebastianoMain,
                slides: sanSabastianSlides
              },
              {
                title: 'Roma: Half Day Tour',
                code:44,
                description: {
                  brief: 'Discover the most important monuments and sites of Rome.',
                  full: 'This tour is designed for those who want to spend a relaxing half day admiring the beauty of Rome, the eternal city, in comfort. Our professional driver will pick you up in front your Hotel or apartment and will drive you around the Rome highlights, you can also decide to hire an Official Tourist Guide, Colosseum Skip the line Tickets (€ 30 pp), Vatican Museum and Sistine Chapel Skip the line Ticket (€ 30 pp), or organize a lunch break in a typical Italian Restaurant. Tour can be customized as you like or you can use the standard solution below.'
                },
                stops: [
                  {
                    title: '1° Stop',
                    description: 'First stop is the Colosseum and the Roman Forum, where you can spend some time admiring the architecture and majesty of a monument that is a symbol of the imperial city.'
                  },
                  {
                    title: '2° Stop',
                    description: 'Then you will move to Piazza Venezia (Altar of the Fatherland and Palazzo Venezia) and Capitol Hill (il Campidoglio) that overlooks the Roman Forum.'
                  },
                  {
                    title: '3° Stop',
                    description: 'The next stop is the Pantheon, a temple to all gods present, past and future rebuilt by Emperor Hadrian between 118 and 128 AD.'
                  },
                  {
                    title: '4° Stop',
                    description: 'We will continue on to Piazza Navona that was built over the ancient stadium of Domitian, where you will be enchanted by the Fountain of the Four Rivers, designed by the painter and sculptor Gian Lorenzo Bernini between 1648 and 1651.'
                  },
                  {
                    title: '5° Stop',
                    description: 'Then we will visit the Trevi Fountain, one of the most famous fountains in the world and following that the Spanish Steps with its famous staircase that will lead us to the Pincio (one of the best views of the city). '
                  },
                  {
                    title: '6° Stop',
                    description: 'We will then descend to Piazza del Popolo where you can visit the Church of Santa Maria del Popolo in which houses works by Caravaggio, Bernini and you can also admire the famous chapel Chigi by Raphael.'
                  },
                  {
                    title: 'Final Stop',
                    description: 'At the end of the tour our driver will drop you off at point of pick up or desired location in the city center.'
                  },
                ],
                duration: '4 hours',
                includes: 'English speaking driver and its vehicle available for the duration of the tour, fuel, tools, passenger liability insurance that is required by law and fuel.',
                excludes: 'Entrance fees to sites, museums, etc and meals.',
                price: '160€ 1-3 persons | 200€ 4-6 persons | 225€ 7-8 persons',
                startingAt: '160€',
                image: RomeHalfMain,
                slides: romeHalfDaySlides
              },
              {
                title: 'Ostia Antica',
                code:45,
                description: {
                  brief: 'It dates back to the Roman Empire, and its ancient ruins tell the story of its power, its glory and its decline.',
                  full: 'Discover the legacy of the Imperial City during a 3-hour guided tour of Ostia Antica, an ancient harbor town, situated only 30 km from Rome that remained buried for over ten centuries. Ostia Antica was one of the most thriving Roman seaports during Julius Caesar’s time and continued to prosper under Tiberius. Go to the imposing amphitheater, which thanks to its impressive state of preservation, still houses numerous concerts and shows. Go back in time and feel like one of the 2,000 spectators once did. Visit the Baths of Neptune, where you’ll admire a beautifully intact mosaic of the sea god. These baths once had numerous pools, saunas and gymnasiums.'
                },
                duration: '4 hours',
                includes: 'English speaking driver and its vehicle available for the duration of the tour, fuel, tools, passenger liability insurance that is required by law and fuel.',
                excludes: 'Entrance fees to sites, museums and meals.',
                price: '180€ 1-3 persons | 220€ 4-6 persons | 255€ 7-8 persons',
                startingAt: '180€',
                image: OstiaAnticaMain,
                slides: ositaSlides
              },
              {
                title: 'Naples and Pompei',
                description: {
                  brief: 'This tour is designed for passengers that want to enjoy a day admiring the artistic and cultural heritage that Naples and Pompeii, has to offer.',
                  full: 'Our local professional driver will accompany you in discovering the most important highlights of Naples and to the excavation of Pompei. You can also decide to hire an official tourist guide or organize a lunch break in a typical Italian Restaurant. The tour can be customized as you like. About Naples: Founded around the middle of the eighth century BC, it was among the hegemonic cities of Ancient Greece thanks to its relationship with Athens. Naples from the ninth century until today is considered one of the main centers of cultural reference in Europe for historical, artistic, political and environmental reasons. The historic center of Naples, the largest in Europe, was recognized by UNESCO as a world heritage site in 1995. The volcanic apparatus Somma - Vesuvius was elected in 1997 by the same international agency as one of the world\'s biosphere reserves. Naples is located almost in the center of the gulf dominated by the massive volcano Vesuvius. Naples is one of the cities with the greatest density of cultural resources and monuments in the world that testify to its historical and artistic evolution. It is one of the oldest cities in Europe. The richness of its historical buildings that characterize different periods gives the site a universal value unequal in the world. It is the result of an overlapping of architectural styles contained in about 2800 years of history.'
                },
                duration: '4 hours',
                includes: 'English speaking driver and its vehicle available for the duration of the tour, fuel, tools, passenger liability insurance that is required by law and fuel.',
                excludes: 'Entrance fees to sites, museums etc, meals and official tour guide.',
                price: '880€ 1-3 persons | 950€ 4-6 persons | 1050€ 7-8 persons',
                startingAt: '880€',
                image: NaplesAndPompeiMainImage,
                slides: naplesSlides
                },
            ],
            faq: [
              {
                question: 'What is included in the price?',
                answer: 'Pickup and dropoff, Free WiFi onboard, All taxes, Fees and Handling Charges, Water and Snacks, Well-maintained car with an English-speaking driver.'
              },
              {
                question: 'What is not included in the price?',
                answer: 'Food and drinks that are not specified in the itinerary, Entrance fees to attractions, Official Tour Guide, Gratuities.'
              },
              {
                question: 'Pick up from Leonardo Da Vinci International Airport',
                answer: '1) Pick up your luggage and leave the baggage claim area; 2) The Driver will have checked your flight number so he will be waiting for you at the arrivals area; 3) By the Arrivals, you will find a lot of drivers. Look for the one holding the signboard with the name you indicated in the booking form; 4) Let the driver help you with your luggage; 5) Enjoy your trip! Remember that Cabbo Drivers know all the secrets of the city.'
              },
              {
                question: 'Pick up from G. B. Pastine International Airport',
                answer: '1) Pick up your luggage and leave the baggage claim area; 2) The Driver will have checked your flight number so he will be waiting for you at the arrivals area; 3) By the Arrivals, you will find a lot of drivers. Look for the one holding the signboard with the name you indicated in the booking form; 4) Let the driver help you with your luggage; 5) Enjoy your trip! Remember that Cabbo Drivers know all the secrets of the city.'
              },
              {
                question: 'Pick up from Termini Train Station',
                answer: '1) Walk out from the Platforms Area; 2) Go outside and look for indications towards Via Marsala; 3) Your Cabbo Ride will be waiting for you in front of "Caffè Trombetta" Via Marsala, 46-48, holding a signboard with the name you indicated on the Booking Form; 4) Let the driver help you with your bags; 5) Enjoy your trip! Remember that Cabbo Drivers know all the secrets of the city.'
              },
              {
                question: 'Pick up from Civitavecchia Port',
                answer: '1) Get off the ship; 2) Search among the drivers the one holding a signboard with the name you specified in the Booking Form. All of our drivers are equipped with a license to come get you right as you disembark; 3) Let the driver help you with your bags; 4) Enjoy your trip! Remember that Cabbo Drivers know all the secrets of the city.'
              },
              {
                question: 'Pick up from another address',
                answer: '1) The driver will meet you outside of the specified location, you will receive an SMS notification if you don’t meet by the indicated time; 2) Let the driver help you with your bags; 3) Enjoy your ride. Don’t forget to ask your Cabbo Driver for delicious restaurant tips or tourist hotspots, they know them all!'
              },
              {
                question: 'What happens after I book?',
                answer: 'Once you complete your booking you will receive a confirmation email. Feel free to reach out to our customer service team at hello@getcabbo.com for any specific questions regarding your booking.'
              },
              {
                question: 'What is the cancellation policy?',
                answer: 'You can cancel free of charge up to 24 hours prior to the booking date. No-shows or cancelation requests the day of your ride are not eligible for a refund. '
              },
              {
                question: 'What should I wear for tours?',
                answer: 'We recommend that you wear comfortable clothing and shoes, and to be equipped with wet weather gear if rain is expected. Please note that religious sites require modest attire - knees and shoulders must be covered.'
              },
              {
                question: 'Local Currency',
                answer: 'We recommend you always keep local currency on you (Euro) as many local merchants, besides CABBO, do not accept cards as payment.'
              },
              {
                question: 'Accessability and Special Needs',
                answer: 'Should anyone in your group have special needs or have advanced accessibility requirements, please specify upon booking and we will make sure to accommodate your needs and customize your ride accordingly.'
              },
              {
                question: 'Any other questions?',
                anwser: 'Please write us an email, we will take just a few hours to get back to you. hello@getcabbo.com'
              }
            ]
        }
    }
    render() {
        return (
            <div>
                <Tour
                    title={this.state.title}
                    subtitle={this.state.subtitle}
                    image={this.state.headerImage}
                    faq={this.state.faq}
                    reviews={this.state.reviews}
                    tours={this.state.tours}
                />
            </div>
        );
    }
}

export default RomeTours;

import React, { Component } from 'react'

import Destination from '../../components/Destination/Destination'

import BarcelonaImage from '../../assets/images/Barcelona.png'

import HalfdayinBarcellona from '../../assets/images/Barcelona/1/Main.png'
import PrivatetourtoMonseratfromBarcellona from '../../assets/images/Barcelona/2/Main.png'
import Sitges_Private5HoursTourfromBarcelona from '../../assets/images/Barcelona/3/Main.png'

export default class Milan extends Component {
  constructor() {
    super()

    this.state = {
      title: 'Barcelona',
      country:'es',
      description:'Barcelona is an enchanting seaside city with boundless culture, fabled architecture and a world-class drinking and dining scene. ',
      story: "Barcelona, facing the Mediterranean sea in the very north of the Spanish coast, is certainly the most cosmopolitan and economically active city in this country. It has always proved its will to be modern, to follow the latest international tendencies or dictate them. To the tourist this is evident in its architecture, which so well reflects the general approach to life in this always pulsating city. Of course, Barcelona has an old history, and there are monuments of Romanesque, Gothic and Renaissance periods or still before, but most characteristic is what has been built during the last, say, 100 years. Barcelona has been a centre of Modernist architecture and is distinguished especially by the works of Antoní Gaudí, who together with his great contemporaries gave new and exciting looks to it.",
      tours: [
        {
          title: 'Barcelona: Private City Tour with Driver and Guide',
          code: 11,
          description: {
            brief: 'Book a vehicle with a local guide and enjoy the flexibility of your private tour.',
            full: 'Discover Barcelona in comfort. Enjoy the maximum flexibility and mobility with private transportation that includes a driver and additional personal guide at your disposition. During this private city tour, you will be introduced to Barcelona, passing by to the most popular squares in town. In some locations, you have the opportunity to have a little walk getting in direct touch with the sights. Choose a pickup point anywhere in Barcelona, such as your hotel, airport, or cruise terminal. Use this opportunity to make the most out of your visit!'
          },
          duration: '5 hours',
          includes: ' English speaking driver and its vehicle available for the duration of the tour, tools, passenger liability insurance that is required by law and fuel.          ',
          excludes: 'Entrance fees to sites, museums and meals.',
          price: '250€ 1-3 persons | 350€ 4-6 persons | 425€ 7-8 persons',
          image: HalfdayinBarcellona
        },
        {
          title: 'Private tour to Monserrat',
          code: 12,
          description: {
            brief: 'Escape Barcelona on a private half-day excursion to the must-see Montserrat Mountain and Monastery. Feast your eyes on the impressive Basilica, the breathtaking views and remarkable rock formations. Enjoy some Catalan snacks and drinks along the way.            ',
            full: "Escape the hustle and bustle of beautiful Barcelona for the afternoon as you enjoy a private half-day tour to the stunning Montserrat Mountain. Poised atop the mountain of Montesrrat, the Benedictine Abbey of Santa Maria de Montserrat is a must-see for those interested in religious history and breathtaking views. Built 1,000 years ago, the Abbey remains one of the most popular destinations for Christians worldwide, with many choosing to go on a pilgrimage here each year. Admire the wonderful mountain views and the impressive Basilica at the top of the mountain, considered by some as the site of the Holy Grail. If tantalizing your historical knowledge isn't enough then feast your eyes on the picturesque landscape situated around the monastery as you make your way to the top of the Montserrat.            Be sure to capture the sprawling scenery and spectacular rock formations on camera, these sights can't be found every day! A trip to the area's traditional food markets for the chance to sample some local delicacies is a tasty way to conclude your tour."
          },
          duration: '5 hours',
          includes: 'English speaking driver and its vehicle available for the duration of the tour, tools, passenger liability insurance that is required by law and fuel. ',
          excludes: 'Entrance fees to sites, museums and meals.',
          price: '450€ 1-3 persons | 550€ 4-6 persons | 650€ 7-8 persons',
          image: PrivatetourtoMonseratfromBarcellona
        },
        {
          title: 'Sitges: Private 5-Hours Tour from Barcelona',
          code: 13,
          description: {
            brief: ' Take a day trip from Barcelona to the beautiful coastal village of Sitges, and enjoy the comfort of private transportation on the 45-minute drive. Discover the attractions of the old town on a guided tour and take in stunning sea and mountain views.            ',
            full: 'On this 5-hour excursion you’ll enjoy private round-trip transportation between your accommodation in Barcelona and Sitges, as well as the services of an official private tour guide, luxury vehicle and separate chauffeur. In just 45 minutes you’ll arrive in Sitges, the lovely coastal town where your guide will take you on a sightseeing tour. You’ll walk through the old town area, which is just steps away from the Mediterranean. From this former fishing village you’ll have beautiful views of both the sea and the nearby mountains. See Port Aiguadolç, and then then walk down the famous Paseo Maritimo of Sitges, to the famous church overlooking the Mediterranean Sea. Finish off your visit with a stop to enjoy paella or fideua at a typical Catalan restaurant (not included in the tour price). If you like, you can also explore some of the many unique shops and boutiques that Sitges has to offer. '
          },
          duration: '5 hours',
          includes: 'English speaking driver and its vehicle available for the duration of the tour, tools, passenger liability insurance that is required by law and fuel. ',
          excludes: 'Entrance fees to sites, museums and meals.',
          price: '450€ 1-3 persons | 550€ 4-6 persons | 650€ 7-8 persons',
          image: Sitges_Private5HoursTourfromBarcelona
        },
      ],
      knowledge: [
        {
          title: 'Don’t plan on taking an Uber',
          content: 'Uber currently doesn’t operate in Spain. The Spanish government banned it in 2015 after some protests against the company’s policies. Use Cabbo for your airport transportation we are compliant with the local laws.'
        },
        {
          title: 'Book your La Sagrada Familia ticket ahead of time',
          content: 'La Sagrada Familia is the most visited site in Barcelona and the second most visited attraction in Spain (after La Alhambra in Granada.) Before you go, you can book your ticket online at the La Sagrada Familia website. They sell out fast, and you may not be able to buy them at the door. Book at least 3 days in advance to be safe.'
        },
        {
          title: ' Use the multi-day metro cards to save money',
          content: 'If you plan to stay in Barcelona for 3 or more days, you can a T-10 multi-trip ticket. It allows you to take both the train and the metro and costs less per ride compared to a single ticket. You also consider getting the Barcelona Hola Travel Card, which gives you unlimited metro and bus rides for 2, 3, 4, or 5 days.'
        },
        {
          title: 'Watch your purse ',
          content: 'Unfortunately, Barcelona is a haven for pickpockets. When you’re in the metro, keep your belongings in front of you and close to your body. Also, pay attention to your surroundings, especially in busy areas like Las Ramblas. Don’t flush your expensive iPhone or watch. Basically, follow the same common sense precautions you take when traveling anywhere.'
        },
        {
          title: 'Don’t spend too much time at Las Ramblas ',
          content: 'Las Ramblas is the main pedestrian street in the city, where you’ll find the popular La Boqueria Market. The problem is that this street is packed with tourist traps like the paella stands you’ll find in the middle of the passageway.'
        },
        {
          title: ' Of course, get some tapas',
          content: 'Tapas are a must when in Spain. These are small portions of food you’re supposed to get for free with a drink. But Barcelona is different so you’ll probably have to pay. The most common types have bread topped with Jamon (ham), seafood, cheese, and all sorts of food. '
        },
        {
          title: 'Speaking of public transport ',
          content: 'you need to purchase tickets prior to boarding.  Purchase from the kiosks at the metro and train stations. You can’t purchase tickets while on the bus or metro.'
        },
        {
          title: 'You pay for table service in Barcelona ',
          content: 'Tipping is not mandatory or expected in Europe.Spanish waitstaff are paid a respectable wage.  If Spanish leave a tip, it’s a few euros or rounding up of the bill.'
        },
        {
          title: 'Don’t be afraid to speak up if your bill is wrong.  ',
          content: 'Always get an itemized receipt when paying for your meal and read it carefully. Know what you are being charged for.'
        },
        {
          title: ' Choose accommodations carefully.',
          content: 'There is no shortage of accommodations in Barcelona.  You will find hotels, apartments and hostels to suit all budgets.  The biggest challenge is finding a property in a good location.  While the train, metro and bus systems are extensive, we find it much easier to stay near the center. You will pay less if you stay outside the city walls, but getting to the tourist hotspots will take you longer!          '
        },
        {
          title: ' Avoid driving in Barcelona, if possible.',
          content: 'Moped drivers zip around cars without a seemingly second thought to their own safety. Horns are blaring, lanes mean nothing and no one seems to have an ounce of patience behind the wheel. Our trusty Cabbo Drivers have mastered driving under these conditions with years of practice.'
        },
      ],
      reviews: [
        {
          avatar: 'https://via.placeholder.com/150/FFFFFF/FFFFFF/FFFFFF',
          name:'P.B.H.',
          stars:'5',
          text: 'Reliable, punctual, careful and helpful driver. Car roomy, clean and tidy. Recommended.'
        },
        {
          avatar: 'https://via.placeholder.com/150/FFFFFF/FFFFFF/FFFFFF',
          name:'Luigi Pedace',
          stars:'5',
          text: "I often use Cabbo and I've never been disappointed. Quality service and very professional drivers."
        },
        {
          avatar: 'https://via.placeholder.com/150/FFFFFF/FFFFFF/FFFFFF',
          name:'Katie Raymond',
          stars:'5',
          text: 'Ideal airport transportation! Could not have been easier. He took our bags and then a smooth ride to our destination'
        },
      ],
      faq: [
        {
          question: 'What is included in the price?',
          answer: <ul>
          <li>Pickup and dropoff</li>
          <li>Free wifi onboard</li>
          <li>All taxes, fees and handling charges</li>
          <li>Water and Snacks</li>
          <li>Well-maintained car with an English-speaking driver</li>
          </ul>,
          key:0
        },
        {
          question: 'What is not included in the price?',
          answer: <ul>
            <li>Food and drinks, not specified in tour itinerary</li>
            <li>Entrance fees to attractions</li>
            <li>Official tour guide (you can include one after the booking)</li>
            <li>Gratuities</li>
          </ul>
        },
        {
          question: 'Pick up from Barcelona Airport',
          answer: <div>
            Otherwise known as Josep Tarradellas Barcelona-El Prat (BCN) <br/>
              1) Pick up your luggage and leave the baggage claim area;<br/>
              2) The Driver will have checked your flight number so he will be waiting for you at the arrivals area;<br/>
              3) By the Arrivals, you will find a lot of drivers. Look for the one holding the signboard with the name you indicated in the booking form;<br/>
              4) Let the driver help you with your luggage;<br/>
              5) Enjoy your trip! Remember that Cabbo Drivers know all the secrets of the city.<br/>
          </div>
        },
        {
          question: 'Pick up from another address',
          answer: <div>1) The driver will meet you outside of the specified location, you will receive an SMS notification if you don’t meet by the indicated time.<br/>
          2) Let the driver help you with your bags;<br/>
          3) Enjoy your ride. Don’t forget to ask your Cabbo Driver for delicious restaurant tips or touris
          t hotspots, they know them all!
          </div>
        },
        {
          question: 'What happens after I book?',
          answer: 'Once you complete your booking you will receive a confirmation email. Feel free to reach out to our customer service team at hello@getcabbo.com for any specific questions regarding your booking. '
        },
        {
          question: 'What is the cancellation policy?          ',
          answer: 'You can cancel free of charge up to 24 hours prior to the booking date. No-shows or cancelation requests the day of your ride are not eligible for a refund. '
        },
        {
          question: 'What should I wear for tours?',
          answer: 'We recommend that you wear comfortable clothing and shoes, and to be equipped with wet weather gear if rain is expected. Please note that religious sites require modest attire - knees and shoulders must be covered. '
        },
        {
          question: 'Local Currency',
          answer: 'We recommend you always keep local currency on you (Euro) as unlike Cabbo, many local merchants do not accept cards as payment '
        },
        {
          question: 'Accessibility and Special Needs',
          answer: ' Should anyone in your group have special needs or have advanced accessibility requirements, please specify upon booking and we will make sure to accommodate your needs and customize your ride accordingly.'
        },
        {
          question: 'Terms of service',
          answer: <ul>
            <li>Please note that the CABBO Drivers are not licensed to be your official tour guide at the local attractions and therefore will not accompany you into the sites. You may, however, hire a professional tour guide at an extra cost
            </li>
            <li>Your CABBO Driver will provide you with their local knowledge and personal recommendations between stops. Since your safety is Cabbo’s top priority, please refrain from distracting the driver during demanding driving conditions
            </li>
            <li>Your CABBO Driver will drop you off and pick you up as close as possible to your stop whilst always respecting local traffic laws
              </li>
              <li>Please consult https://getcabbo.com/toc for the full list of Terms and Conditions
            </li>
          </ul>,
          key: '0'
        },
        {
          question: 'Any other questions? ',
          answer: 'Please write us an email, we will take just a few hours to get back to you. hello@getcabbo.com           ',
          key: '0'
        },
      ]
    }
  }
  render() {
    return (
      <div id={this.state.title}>
      <Destination
        title={this.state.title}
        image={BarcelonaImage}
        description={this.state.description}
        story={this.state.story}
        tours={this.state.tours}
        knowledge={this.state.knowledge}
        faq={this.state.faq}
        country={this.state.country}
        reviews={this.state.reviews}
      />
      </div>
    )
  }
}

import React, { Component } from 'react'
import ReactPlayer from "react-player"

import FAQ from '../../components/FAQ/FAQ'
import PartnersForm from '../../components/Partners/PartnersForm'
import { Col, Container, Row } from 'react-bootstrap'
import ReviewsSlider from '../../components/Reviews/ReviewsSlider'
import FullscreenHeader from  '../../components/PageHeader/FullscreenHeader'
// import BookingForm from '../../components/BookingForm/BookingForm'
import { Link } from 'react-router-dom'
import './Partners.scss'

import transferMockup from '../../assets/images/transfer-mockup-large.jpg'
import dashMockup from '../../assets/images/cabbo-dash2.png'

import airbnbLogo from '../../assets/images/airbnb-logo.png'
import vrboLogo from '../../assets/images/vrbo-logo.png'
import bookingLogo from '../../assets/images/booking-com-logo.png'
import hotelsLogo from '../../assets/images/hotels-com-logo.svg'

import innovation from '../../assets/images/innovation.svg'
import brandAwareness from '../../assets/images/brand-awareness.svg'
import dollarCoin from '../../assets/images/dollar-coin.svg'
import technicalSupport from '../../assets/images/technical-support.svg'
import safetyFirst from '../../assets/images/ppe.svg'
import integrations from '../../assets/images/integrations3.png'


import tedxmilanoLogo from '../../assets/images/Partners/tedxmilano.png'
import techstarsLogo from '../../assets/images/Partners/techstars.png'
import imperialbusinesscollegeLogo from '../../assets/images/Partners/Imperial-College-Business-School.png'
import theleadinghotelsLogo from '../../assets/images/Partners/The_Leading_Hotels_of_the_World_logo.png'
import radissaLogo from '../../assets/images/Partners/Radisson.png'
import viaggidellelefanteLogo from '../../assets/images/Partners/viaggi.png'
import guido from '../../assets/images/partners-dark.jpg'


export default class Partners extends Component {
  constructor() {
    super()

    this.state = {
      openForm: false,
      buttonVisible: false,
      title: 'CABBO is your partner for smart mobility.',
      subtitle: 'Contact us to know what we can do for your business!',
      coountryName: 'Barcelona',
      countryCode: 'es',
      image: guido,
      partners: [
        {
          name: '',
          logo: theleadinghotelsLogo
        },
        {
          name: '',
          logo: techstarsLogo
        },
        {
          name: '',
          logo: imperialbusinesscollegeLogo
        },
        {
          name: '',
          logo: tedxmilanoLogo
        },
        {
          name: '',
          logo: viaggidellelefanteLogo
        },
      ],
      section1Blurbs: [
        {
          image: innovation,
          heading: 'A technology driven platform',
          subHeading: 'Which makes us flexible and cost saving'
        },
        {
          image: brandAwareness,
          heading: 'Spread brand awareness',
          subHeading: 'We provide a landing page personalized just for your business'
        },
        {
          image: dollarCoin,
          heading: 'Save time and boost your ancillary revenue',
          subHeading: 'Selling extra services has never been easier and more profitable with us'
        },
        {
          image: technicalSupport,
          heading: 'Safe and reliable, with 24/7 assistance',
          subHeading: 'We will provide assistance for you and our guests 24 hours a day'
        },
        {
          image: safetyFirst,
          heading: 'Safety First',
          subHeading: 'Now more then ever we care about your guest and our driver safety.'
        },
        {
          image: integrations,
          heading: 'We interface with...',
          subHeading: 'we build our solution so to make things easier for you and provide your guests with an easy-to-use service.'
        }
      ],
      integrations: [
        {
          name: "Airbnb",
          logo: airbnbLogo
        },
        {
          name: "Booking.com",
          logo: bookingLogo
        },
        {
          name: "vrbo",
          logo: vrboLogo
        },
        {
          name: "Hotels.com",
          logo: hotelsLogo
        }
      ],
      faq: [
        {
          question: 'How can I get started?',
          answer: "The setup of our service is easy and fast. All we need from you is the links and exact addresses of your apartments. Then we are going to create your personalised landing pages, send them to you and you are ready to start offering CABBO to your guests! Just fill in the application form and a CABBO representative will reach out and assist you to get started!"
        },
        {
          question: 'How do I know if my guests book a CABBO transfer? ',
          answer: "Through our Partner App you can see the scheduled list with all your CABBO transfers, along with your guest's information & preferences, transfer details and flight monitoring for possible delays. You can choose to be updated and notified through emails, once the status of a transfer has been change"
        },
        {
          question: 'What if my guest wants to change the reservation details?',
          answer: "Our 24/7 Customer Support Team is always available to handle any request from your guests, even if it's a new transfer or any change happens in already booked ones."
        },
        {
          question: 'How and when do I get paid?',
          answer: "You are receiving a commission for every transfer operated with CABBO in your Hotel/apartment/B&B. You are getting paid on a monthly basis for the transfers that were operated in the previous month. The payment is taking place through PayPal or bank transfer."
        },
        {
          question: 'What are the fees?',
          answer: "Cabbo is completely free for hoteliers/B&B and an extra source of revenue."
        },
        {
          question: 'Are you GDPR compliant?',
          answer: "Yes, we are GDPR compliant. All of Cabbo customer data is treated in a way that conforms with GDPR."
        },
      ]
    }
  }

  componentDidMount() {
    window.addEventListener('scroll', () => {
      if ((document.documentElement.scrollTop > 100) && !this.state.buttonVisible) {
        this.setState({buttonVisible: true})
      }
      if ((document.documentElement.scrollTop < 100) && this.state.buttonVisible) {
        this.setState({buttonVisible: false})
      }
    })
  }

  openForm = () => {
    this.setState({openForm: true})
  }

  render() {
    const {title, subtitle, countryName, countryCode, image, faq, reviews, partners, section1Blurbs, integrations, buttonVisible} = this.state
    return (
      <div id="partners-page">
        <FullscreenHeader title={title} subtitle={subtitle} backgroundImage={image} countryName={countryName} country={countryCode} buttonText="Get In Touch" buttonClickAction={this.openForm}/>

        <Container className="pt-5">
          <h2 className='title text-center'>Our solution for accomodation facilities</h2>
          <Row className="align-items-center py-3 py-md-5">
            <Col xs={12} md={6} className="mb-5 mb-md-0">
              <img alt="transfer mockup" src={transferMockup} className="rounded shadow"/>
            </Col>
            <Col xs={12} md={6}>
              <h2>Customized landing page</h2>
              <p className="lead">We provide each partner with a free website customized by us, in which guests can autonomously book their fully automated transfer service.</p>
              <p className="lead mb-0">We save accommodation facilities time and increase their earnings with advanced conversion techniques.</p>
            </Col>
          </Row>

          <Row className="align-items-center py-3 py-md-5">
            <Col xs={12} md={6} className="mb-5 mb-md-0 order-md-2">
              <img alt="dashboard mockup" src={dashMockup} className="rounded shadow"/>
            </Col>
            <Col xs={12} md={6}>
              <h2>Your control panel</h2>
              <p className="lead mb-0">We provide each partner with a dashboard with details on sold transfers, generated revenues and travelers data. There is also a space to manually book transfers in case you want to do it for your customer.</p>
            </Col>
          </Row>
        </Container>

        <Container className="mt-5">
          <center>
          <ReactPlayer
            url="https://youtu.be/KNxc3lbQXR0"
            />
          </center>
        </Container>

        <Container className="py-5">
          <Row>
            <Col xs={12}>
              <Row className="partner-benefits justify-content-center">
                {section1Blurbs.map((blurb, i) => {
                  return (
                    <Col lg={6} key={i} className="mb-5 partner-benefit d-flex align-items-center">
                      <img className="mr-3" src={blurb.image} alt={blurb.heading} />
                      <div className="d-flex flex-column justify-content-center px-0">
                        <h4>{blurb.heading}</h4>
                        <p className="lead mt-0 mb-0">{blurb.subHeading}</p>
                      </div>
                    </Col>
                  )
                })}
              </Row>
              <Row className="mt-4">
                <Col>
                  <p className="lead">
                    We provide a ready-to-use solution to manage transfers inside and outside the city and ad-hoc solutions personalized according to your company.
                  </p>

                  <p className="lead">
                    With CABBO you will have access to easy-to-use digital tools to book and manage transfers, including a dashboard and a branded landing page to make yourself accessible for everyone.
                  </p>

                  <p className="lead font-weight-bold text-yellow">
                    This and more completely for free!
                  </p>

                  <p className="lead">
                    <Link to='/contact'>Contact us</Link> and you will receive a feedback within 48 hours.
                    </p>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Container>

          <OurPartners partners={partners}/>

          <ReviewsSlider/>

          {/* <Container className="d-lg-none">
            <BookingForm data-aos='fade-left' data-aos-delay='200' title={countryName} country={countryCode}/>
            </Container> */}

            <Container className="mt-5">
              <FAQ data={faq}/>
            </Container>

            <div class="fixed-button-container py-3">
              <button className={`btn btn-primary py-3 ${!buttonVisible && 'btn-hidden'}`} onClick={() => this.openForm()}>
                Get In Touch
              </button>
            </div>

            {this.state.openForm === true ?
              <>
              <div className="popup-overlay d-flex align-items-center justify-content-center" onClick={() => this.setState({openForm: false})}>
              </div>
              <PartnersForm closeForm={() => this.setState({openForm: false})} />
              <div className="close-card" onClick={() => this.setState({openForm: false})}>X</div>
              </>
            : <></>}
          </div>
        );
      }
    }

    class OurPartners extends Component {
      render() {
        const { partners } = this.props;

        return (
          <Container fluid className="bg-light py-5">
            <Container>
              <Row className='mb-2'>
                <Col xs={12}>
                  <h2 className="text-uppercase">Our Partners</h2>
                </Col>
              </Row>
              <Row className="mb-n5 align-items-center justify-content-center">
                {partners.map((partner, i) => {
                  return (
                    <Col xs={6} md={4} lg={4} className="mb-5 d-flex align-items-center justify-content-center">
                      <img src={partner.logo} alt={partner.name} className="partner-logo"/>
                    </Col>
                  )
                })}
              </Row>
            </Container>
          </Container>
        )
      }
    }

import React, { Component } from 'react'

import tourImage from '../../assets/images/tour-bg.jpg'

import PageHeader from '../../components/PageHeader/PageHeader'
import Cities from '../../components/Cities/Cities'
import CitiesComingSoon from '../../components/Cities/CitiesComingSoon'

import './Tour.scss'

export default class Tour extends Component {
  render() {
    return (
      <div id='to'>
        <PageHeader title='Tours' subtitle='Where would you like to go?' image={tourImage} />

        <Cities title='In which city will you stay during your holiday?' page="tours"/>

        <CitiesComingSoon />
      </div>
    )
  }
}

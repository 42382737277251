import React, { Component } from 'react'


import { Container, Form , Button } from 'react-bootstrap'

import './ContactForm.scss'

export default class ContactForm extends Component {

  constructor(props) {
    super(props)
    this.state = {
      name:'',
      email:'',
      message:'',
      isLoading:false
    }
    this.handleChange = this.handleChange.bind(this)
    this.sendMail = this.sendMail.bind(this);
  }

  sendMail() {
    this.setState({ isLoading:true});
    window.Email.send({
      Host : "getcabbo.com",
      Username : "mailhook@getcabbo.com",
      Password : "Y68GkE@]BfUW",
      To : 'mailhook@getcabbo.com',
      From : "mailhook@getcabbo.com",
      Subject : "Message From " + this.state.name,
      Body : this.state.message + "<br/> Reply at " + this.state.email
   }).then((message) => {
        if(message){
          this.setState({ isLoading:false});
          this.setState({ isCompleted:true});
          this.setState({
            name:'',
            email:'',
            message:''
          });

          this.setState({ token:false});
        }
      });
  }

  handleChange = (param, e) => {
      this.setState({ [param]: e.target.value }, this.handleTotal);
    }


  render() {
    return (
            <Form as={Container} className='px-0'>

              <Form.Group controlId="formName">
                <Form.Label>Name</Form.Label>
                <Form.Control type="text" placeholder="Name" defaultValue={this.state.name}  onChange={this.handleChange.bind(this, 'name')}/>
              </Form.Group>

              <Form.Group controlId="formEmail">
                <Form.Label>Email</Form.Label>
                <Form.Control type="email" placeholder="Email" defaultValue={this.state.email} onChange={this.handleChange.bind(this, 'email')}/>
              </Form.Group>

              <Form.Group controlId="formmessage">
                <Form.Label>Message</Form.Label>
                <Form.Control as="textarea" rows="3" placeholder="Message" defaultValue={this.state.message} onChange={this.handleChange.bind(this, 'message')}/>
              </Form.Group>


              <Button variant="primary" type="submit" onClick={this.sendMail}>
                {this.state.isLoading ? <span>Submitting...</span> : <span>Submit</span> }

              </Button>
            </Form>
    )
  }
}
